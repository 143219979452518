import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Api} from './api.service';
import {RestaurantCuisinesDTO} from '../dto/restaurant-cuisines-dto';
import {RestaurantDTO} from '../dto/restaurant-dto';
import {TcodeCuisineDTO} from '../dto/tcode-cuisine-dto';

@Injectable()
export class BaseRestaurantCuisinesService {

	constructor(public api:Api) {

	}

	getRestaurantCuisinesById(id:number) : Observable<RestaurantCuisinesDTO> {

		return this.api.post<RestaurantCuisinesDTO>("RestaurantCuisines/getRestaurantCuisinesById", id);
	}

	getRestaurantCuisines(restaurantCuisinesDTO:RestaurantCuisinesDTO) : Observable<RestaurantCuisinesDTO> {

		return this.api.post<RestaurantCuisinesDTO>("RestaurantCuisines/getRestaurantCuisines", restaurantCuisinesDTO);
	}

	getRestaurantCuisinessByRestaurantId(restaurantDTO:RestaurantDTO) : Observable<RestaurantDTO> {

		return this.api.post<RestaurantDTO>("RestaurantCuisines/getRestaurantCuisinessByRestaurantId", restaurantDTO);
	}

	getRestaurantCuisinesListByRestaurantId(restaurantId:number) : Observable<Array<RestaurantCuisinesDTO>> {

		return this.api.post<Array<RestaurantCuisinesDTO>>("RestaurantCuisines/getRestaurantCuisinesListByRestaurantId", restaurantId);
	}

	getRestaurantCuisinesMapByRestaurantId(restaurantId:number) : Observable<Map<number, RestaurantCuisinesDTO>>  {

		return this.api.post<Map<number, RestaurantCuisinesDTO>>("RestaurantCuisines/getRestaurantCuisinesMapByRestaurantId", restaurantId);
	}

	getRestaurantCuisinessByCuisineId(tcodeCuisineDTO:TcodeCuisineDTO) : Observable<TcodeCuisineDTO> {

		return this.api.post<TcodeCuisineDTO>("RestaurantCuisines/getRestaurantCuisinessByCuisineId", tcodeCuisineDTO);
	}

	getRestaurantCuisinesListByCuisineId(cuisineId:number) : Observable<Array<RestaurantCuisinesDTO>> {

		return this.api.post<Array<RestaurantCuisinesDTO>>("RestaurantCuisines/getRestaurantCuisinesListByCuisineId", cuisineId);
	}

	getRestaurantCuisinesMapByCuisineId(cuisineId:number) : Observable<Map<number, RestaurantCuisinesDTO>>  {

		return this.api.post<Map<number, RestaurantCuisinesDTO>>("RestaurantCuisines/getRestaurantCuisinesMapByCuisineId", cuisineId);
	}

	getRestaurantCuisinesList() : Observable<RestaurantCuisinesDTO[]> {

		return this.api.get<RestaurantCuisinesDTO[]>("RestaurantCuisines/getRestaurantCuisinesList");
	}

	getRestaurantCuisinesMap() : Observable<Map<number, RestaurantCuisinesDTO>> {

		return this.api.get<Map<number, RestaurantCuisinesDTO>>("RestaurantCuisines/getRestaurantCuisinesMap");
	}

	searchRestaurantCuisinesList(restaurantCuisinesDTO:RestaurantCuisinesDTO) : Observable<Array<RestaurantCuisinesDTO>> {

		return this.api.post<Array<RestaurantCuisinesDTO>>("RestaurantCuisines/searchRestaurantCuisinesList", restaurantCuisinesDTO);
	}

	searchRestaurantCuisinesMap(restaurantCuisinesDTO:RestaurantCuisinesDTO) : Observable<Map<number, RestaurantCuisinesDTO>> {

		return this.api.post<Map<number, RestaurantCuisinesDTO>>("RestaurantCuisines/searchRestaurantCuisinesMap", restaurantCuisinesDTO);
	}

	addRestaurantCuisines(restaurantCuisinesDTO:RestaurantCuisinesDTO) : Observable<RestaurantCuisinesDTO> {

		return this.api.post<RestaurantCuisinesDTO>("RestaurantCuisines/addRestaurantCuisines", restaurantCuisinesDTO);
	}

	addRestaurantCuisinesList(restaurantCuisinesList:Array<RestaurantCuisinesDTO>) : Observable<Array<RestaurantCuisinesDTO>> {

		return this.api.post<Array<RestaurantCuisinesDTO>>("RestaurantCuisines/addRestaurantCuisinesList", restaurantCuisinesList);
	}

	updateRestaurantCuisines(restaurantCuisinesDTO:RestaurantCuisinesDTO) : Observable<RestaurantCuisinesDTO> {

		return this.api.post<RestaurantCuisinesDTO>("RestaurantCuisines/updateRestaurantCuisines", restaurantCuisinesDTO);
	}

	updateRestaurantCuisinesList(restaurantCuisinesList:Array<RestaurantCuisinesDTO>) : Observable<Array<RestaurantCuisinesDTO>> {

		return this.api.post<Array<RestaurantCuisinesDTO>>("RestaurantCuisines/updateRestaurantCuisinesList", restaurantCuisinesList);
	}

	saveRestaurantCuisines(restaurantCuisinesDTO:RestaurantCuisinesDTO) : Observable<RestaurantCuisinesDTO> {

		return this.api.post<RestaurantCuisinesDTO>("RestaurantCuisines/saveRestaurantCuisines", restaurantCuisinesDTO);
	}

	saveRestaurantCuisinesList(restaurantCuisinesList:Array<RestaurantCuisinesDTO>) : Observable<Array<RestaurantCuisinesDTO>> {

		return this.api.post<Array<RestaurantCuisinesDTO>>("RestaurantCuisines/saveRestaurantCuisinesList", restaurantCuisinesList);
	}

	activateRestaurantCuisinesById(id:number) : Observable<number>{

		return this.api.post<number>("RestaurantCuisines/activateRestaurantCuisinesById", id);
	}

	activateRestaurantCuisines(restaurantCuisinesDTO:RestaurantCuisinesDTO) : Observable<RestaurantCuisinesDTO> {

		return this.api.post<RestaurantCuisinesDTO>("RestaurantCuisines/activateRestaurantCuisines", restaurantCuisinesDTO);
	}

	activateRestaurantCuisinesList(restaurantCuisinesList:Array<RestaurantCuisinesDTO>) : Observable<Array<RestaurantCuisinesDTO>> {

		return this.api.post<Array<RestaurantCuisinesDTO>>("RestaurantCuisines/activateRestaurantCuisinesList", restaurantCuisinesList);
	}

	deactivateRestaurantCuisinesById(id:number) : Observable<number> {

		return this.api.post<number>("RestaurantCuisines/deactivateRestaurantCuisinesById", id);
	}

	deactivateRestaurantCuisines(restaurantCuisinesDTO:RestaurantCuisinesDTO) : Observable<RestaurantCuisinesDTO> {

		return this.api.post<RestaurantCuisinesDTO>("RestaurantCuisines/deactivateRestaurantCuisines", restaurantCuisinesDTO);
	}

	deactivateRestaurantCuisinesList(restaurantCuisinesList:Array<RestaurantCuisinesDTO>) : Observable<Array<RestaurantCuisinesDTO>> {

		return this.api.post<Array<RestaurantCuisinesDTO>>("RestaurantCuisines/deactivateRestaurantCuisinesList", restaurantCuisinesList);
	}
}