import {CustomerOfferDTO} from '../dto/customer-offer-dto';

export class BaseTcodeRejectionReasonDTO {

	id:number;
	reasonEnglish:string;
	reasonArabic:string;
	active:boolean;
	recCrtDate:string;
	recUpdtDate:string;
	recCrtUser:number;
	recUpdtUser:number;

	customerOfferList:Array<CustomerOfferDTO> = new Array<CustomerOfferDTO>();
}