import {CustomerDTO} from '../dto/customer-dto';
import {RestaurantDTO} from '../dto/restaurant-dto';

export class BaseRestReturnCustomerDTO {

	id:number;
	restaurantDTO:RestaurantDTO = new RestaurantDTO();
	customerDTO:CustomerDTO = new CustomerDTO();
	active:boolean;
	recCrtDate:string;
	recUpdtDate:string;
	recCrtUser:number;
	recUpdtUser:number;
}