import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Api} from './api.service';
import {TcodeOccupationDTO} from '../dto/tcode-occupation-dto';

@Injectable()
export class BaseTcodeOccupationService {

	constructor(public api:Api) {

	}

	getTcodeOccupationById(id:number) : Observable<TcodeOccupationDTO> {

		return this.api.post<TcodeOccupationDTO>("TcodeOccupation/getTcodeOccupationById", id);
	}

	getTcodeOccupation(tcodeOccupationDTO:TcodeOccupationDTO) : Observable<TcodeOccupationDTO> {

		return this.api.post<TcodeOccupationDTO>("TcodeOccupation/getTcodeOccupation", tcodeOccupationDTO);
	}

	getTcodeOccupationList() : Observable<TcodeOccupationDTO[]> {

		return this.api.get<TcodeOccupationDTO[]>("TcodeOccupation/getTcodeOccupationList");
	}

	getTcodeOccupationMap() : Observable<Map<number, TcodeOccupationDTO>> {

		return this.api.get<Map<number, TcodeOccupationDTO>>("TcodeOccupation/getTcodeOccupationMap");
	}

	searchTcodeOccupationList(tcodeOccupationDTO:TcodeOccupationDTO) : Observable<Array<TcodeOccupationDTO>> {

		return this.api.post<Array<TcodeOccupationDTO>>("TcodeOccupation/searchTcodeOccupationList", tcodeOccupationDTO);
	}

	searchTcodeOccupationMap(tcodeOccupationDTO:TcodeOccupationDTO) : Observable<Map<number, TcodeOccupationDTO>> {

		return this.api.post<Map<number, TcodeOccupationDTO>>("TcodeOccupation/searchTcodeOccupationMap", tcodeOccupationDTO);
	}

	addTcodeOccupation(tcodeOccupationDTO:TcodeOccupationDTO) : Observable<TcodeOccupationDTO> {

		return this.api.post<TcodeOccupationDTO>("TcodeOccupation/addTcodeOccupation", tcodeOccupationDTO);
	}

	addTcodeOccupationList(tcodeOccupationList:Array<TcodeOccupationDTO>) : Observable<Array<TcodeOccupationDTO>> {

		return this.api.post<Array<TcodeOccupationDTO>>("TcodeOccupation/addTcodeOccupationList", tcodeOccupationList);
	}

	updateTcodeOccupation(tcodeOccupationDTO:TcodeOccupationDTO) : Observable<TcodeOccupationDTO> {

		return this.api.post<TcodeOccupationDTO>("TcodeOccupation/updateTcodeOccupation", tcodeOccupationDTO);
	}

	updateTcodeOccupationList(tcodeOccupationList:Array<TcodeOccupationDTO>) : Observable<Array<TcodeOccupationDTO>> {

		return this.api.post<Array<TcodeOccupationDTO>>("TcodeOccupation/updateTcodeOccupationList", tcodeOccupationList);
	}

	saveTcodeOccupation(tcodeOccupationDTO:TcodeOccupationDTO) : Observable<TcodeOccupationDTO> {

		return this.api.post<TcodeOccupationDTO>("TcodeOccupation/saveTcodeOccupation", tcodeOccupationDTO);
	}

	saveTcodeOccupationList(tcodeOccupationList:Array<TcodeOccupationDTO>) : Observable<Array<TcodeOccupationDTO>> {

		return this.api.post<Array<TcodeOccupationDTO>>("TcodeOccupation/saveTcodeOccupationList", tcodeOccupationList);
	}

	activateTcodeOccupationById(id:number) : Observable<number>{

		return this.api.post<number>("TcodeOccupation/activateTcodeOccupationById", id);
	}

	activateTcodeOccupation(tcodeOccupationDTO:TcodeOccupationDTO) : Observable<TcodeOccupationDTO> {

		return this.api.post<TcodeOccupationDTO>("TcodeOccupation/activateTcodeOccupation", tcodeOccupationDTO);
	}

	activateTcodeOccupationList(tcodeOccupationList:Array<TcodeOccupationDTO>) : Observable<Array<TcodeOccupationDTO>> {

		return this.api.post<Array<TcodeOccupationDTO>>("TcodeOccupation/activateTcodeOccupationList", tcodeOccupationList);
	}

	deactivateTcodeOccupationById(id:number) : Observable<number> {

		return this.api.post<number>("TcodeOccupation/deactivateTcodeOccupationById", id);
	}

	deactivateTcodeOccupation(tcodeOccupationDTO:TcodeOccupationDTO) : Observable<TcodeOccupationDTO> {

		return this.api.post<TcodeOccupationDTO>("TcodeOccupation/deactivateTcodeOccupation", tcodeOccupationDTO);
	}

	deactivateTcodeOccupationList(tcodeOccupationList:Array<TcodeOccupationDTO>) : Observable<Array<TcodeOccupationDTO>> {

		return this.api.post<Array<TcodeOccupationDTO>>("TcodeOccupation/deactivateTcodeOccupationList", tcodeOccupationList);
	}
}