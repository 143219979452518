import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Api} from './api.service';
import {OfferDTO} from '../dto/offer-dto';
import {RestaurantDTO} from '../dto/restaurant-dto';

@Injectable()
export class BaseOfferService {

	constructor(public api:Api) {

	}

	getOfferById(id:number) : Observable<OfferDTO> {

		return this.api.post<OfferDTO>("Offer/getOfferById", id);
	}

	getOffer(offerDTO:OfferDTO) : Observable<OfferDTO> {

		return this.api.post<OfferDTO>("Offer/getOffer", offerDTO);
	}

	getOffersByRestaurantId(restaurantDTO:RestaurantDTO) : Observable<RestaurantDTO> {

		return this.api.post<RestaurantDTO>("Offer/getOffersByRestaurantId", restaurantDTO);
	}

	getOfferListByRestaurantId(restaurantId:number) : Observable<Array<OfferDTO>> {

		return this.api.post<Array<OfferDTO>>("Offer/getOfferListByRestaurantId", restaurantId);
	}

	getOfferMapByRestaurantId(restaurantId:number) : Observable<Map<number, OfferDTO>>  {

		return this.api.post<Map<number, OfferDTO>>("Offer/getOfferMapByRestaurantId", restaurantId);
	}

	getOfferList() : Observable<OfferDTO[]> {

		return this.api.get<OfferDTO[]>("Offer/getOfferList");
	}

	getOfferMap() : Observable<Map<number, OfferDTO>> {

		return this.api.get<Map<number, OfferDTO>>("Offer/getOfferMap");
	}

	searchOfferList(offerDTO:OfferDTO) : Observable<Array<OfferDTO>> {

		return this.api.post<Array<OfferDTO>>("Offer/searchOfferList", offerDTO);
	}

	searchOfferMap(offerDTO:OfferDTO) : Observable<Map<number, OfferDTO>> {

		return this.api.post<Map<number, OfferDTO>>("Offer/searchOfferMap", offerDTO);
	}

	addOffer(offerDTO:OfferDTO) : Observable<OfferDTO> {

		return this.api.post<OfferDTO>("Offer/addOffer", offerDTO);
	}

	addOfferList(offerList:Array<OfferDTO>) : Observable<Array<OfferDTO>> {

		return this.api.post<Array<OfferDTO>>("Offer/addOfferList", offerList);
	}

	updateOffer(offerDTO:OfferDTO) : Observable<OfferDTO> {

		return this.api.post<OfferDTO>("Offer/updateOffer", offerDTO);
	}

	updateOfferList(offerList:Array<OfferDTO>) : Observable<Array<OfferDTO>> {

		return this.api.post<Array<OfferDTO>>("Offer/updateOfferList", offerList);
	}

	saveOffer(offerDTO:OfferDTO) : Observable<OfferDTO> {

		return this.api.post<OfferDTO>("Offer/saveOffer", offerDTO);
	}

	saveOfferList(offerList:Array<OfferDTO>) : Observable<Array<OfferDTO>> {

		return this.api.post<Array<OfferDTO>>("Offer/saveOfferList", offerList);
	}

	activateOfferById(id:number) : Observable<number>{

		return this.api.post<number>("Offer/activateOfferById", id);
	}

	activateOffer(offerDTO:OfferDTO) : Observable<OfferDTO> {

		return this.api.post<OfferDTO>("Offer/activateOffer", offerDTO);
	}

	activateOfferList(offerList:Array<OfferDTO>) : Observable<Array<OfferDTO>> {

		return this.api.post<Array<OfferDTO>>("Offer/activateOfferList", offerList);
	}

	deactivateOfferById(id:number) : Observable<number> {

		return this.api.post<number>("Offer/deactivateOfferById", id);
	}

	deactivateOffer(offerDTO:OfferDTO) : Observable<OfferDTO> {

		return this.api.post<OfferDTO>("Offer/deactivateOffer", offerDTO);
	}

	deactivateOfferList(offerList:Array<OfferDTO>) : Observable<Array<OfferDTO>> {

		return this.api.post<Array<OfferDTO>>("Offer/deactivateOfferList", offerList);
	}
}