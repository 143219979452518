import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Api} from './api.service';
import {DeviceDTO} from '../dto/device-dto';
import {UserDTO} from '../dto/user-dto';

@Injectable()
export class BaseDeviceService {

	constructor(public api:Api) {

	}

	getDeviceById(id:number) : Observable<DeviceDTO> {

		return this.api.post<DeviceDTO>("Device/getDeviceById", id);
	}

	getDevice(deviceDTO:DeviceDTO) : Observable<DeviceDTO> {

		return this.api.post<DeviceDTO>("Device/getDevice", deviceDTO);
	}

	getDevicesByUserId(userDTO:UserDTO) : Observable<UserDTO> {

		return this.api.post<UserDTO>("Device/getDevicesByUserId", userDTO);
	}

	getDeviceListByUserId(userId:number) : Observable<Array<DeviceDTO>> {

		return this.api.post<Array<DeviceDTO>>("Device/getDeviceListByUserId", userId);
	}

	getDeviceMapByUserId(userId:number) : Observable<Map<number, DeviceDTO>>  {

		return this.api.post<Map<number, DeviceDTO>>("Device/getDeviceMapByUserId", userId);
	}

	getDeviceList() : Observable<DeviceDTO[]> {

		return this.api.get<DeviceDTO[]>("Device/getDeviceList");
	}

	getDeviceMap() : Observable<Map<number, DeviceDTO>> {

		return this.api.get<Map<number, DeviceDTO>>("Device/getDeviceMap");
	}

	searchDeviceList(deviceDTO:DeviceDTO) : Observable<Array<DeviceDTO>> {

		return this.api.post<Array<DeviceDTO>>("Device/searchDeviceList", deviceDTO);
	}

	searchDeviceMap(deviceDTO:DeviceDTO) : Observable<Map<number, DeviceDTO>> {

		return this.api.post<Map<number, DeviceDTO>>("Device/searchDeviceMap", deviceDTO);
	}

	addDevice(deviceDTO:DeviceDTO) : Observable<DeviceDTO> {

		return this.api.post<DeviceDTO>("Device/addDevice", deviceDTO);
	}

	addDeviceList(deviceList:Array<DeviceDTO>) : Observable<Array<DeviceDTO>> {

		return this.api.post<Array<DeviceDTO>>("Device/addDeviceList", deviceList);
	}

	updateDevice(deviceDTO:DeviceDTO) : Observable<DeviceDTO> {

		return this.api.post<DeviceDTO>("Device/updateDevice", deviceDTO);
	}

	updateDeviceList(deviceList:Array<DeviceDTO>) : Observable<Array<DeviceDTO>> {

		return this.api.post<Array<DeviceDTO>>("Device/updateDeviceList", deviceList);
	}

	saveDevice(deviceDTO:DeviceDTO) : Observable<DeviceDTO> {

		return this.api.post<DeviceDTO>("Device/saveDevice", deviceDTO);
	}

	saveDeviceList(deviceList:Array<DeviceDTO>) : Observable<Array<DeviceDTO>> {

		return this.api.post<Array<DeviceDTO>>("Device/saveDeviceList", deviceList);
	}

	activateDeviceById(id:number) : Observable<number>{

		return this.api.post<number>("Device/activateDeviceById", id);
	}

	activateDevice(deviceDTO:DeviceDTO) : Observable<DeviceDTO> {

		return this.api.post<DeviceDTO>("Device/activateDevice", deviceDTO);
	}

	activateDeviceList(deviceList:Array<DeviceDTO>) : Observable<Array<DeviceDTO>> {

		return this.api.post<Array<DeviceDTO>>("Device/activateDeviceList", deviceList);
	}

	deactivateDeviceById(id:number) : Observable<number> {

		return this.api.post<number>("Device/deactivateDeviceById", id);
	}

	deactivateDevice(deviceDTO:DeviceDTO) : Observable<DeviceDTO> {

		return this.api.post<DeviceDTO>("Device/deactivateDevice", deviceDTO);
	}

	deactivateDeviceList(deviceList:Array<DeviceDTO>) : Observable<Array<DeviceDTO>> {

		return this.api.post<Array<DeviceDTO>>("Device/deactivateDeviceList", deviceList);
	}
}