import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Api} from './api.service';
import {UserDTO} from '../dto/user-dto';
import {SupplierDTO} from '../dto/supplier-dto';
import {RoleDTO} from '../dto/role-dto';

@Injectable()
export class BaseUserService {

	constructor(public api:Api) {

	}

	getUserById(id:number) : Observable<UserDTO> {

		return this.api.post<UserDTO>("User/getUserById", id);
	}

	getUser(userDTO:UserDTO) : Observable<UserDTO> {

		return this.api.post<UserDTO>("User/getUser", userDTO);
	}

	getUsersBySupplierId(supplierDTO:SupplierDTO) : Observable<SupplierDTO> {

		return this.api.post<SupplierDTO>("User/getUsersBySupplierId", supplierDTO);
	}

	getUserListBySupplierId(supplierId:number) : Observable<Array<UserDTO>> {

		return this.api.post<Array<UserDTO>>("User/getUserListBySupplierId", supplierId);
	}

	getUserMapBySupplierId(supplierId:number) : Observable<Map<number, UserDTO>>  {

		return this.api.post<Map<number, UserDTO>>("User/getUserMapBySupplierId", supplierId);
	}

	getUsersByRoleId(roleDTO:RoleDTO) : Observable<RoleDTO> {

		return this.api.post<RoleDTO>("User/getUsersByRoleId", roleDTO);
	}

	getUserListByRoleId(roleId:number) : Observable<Array<UserDTO>> {

		return this.api.post<Array<UserDTO>>("User/getUserListByRoleId", roleId);
	}

	getUserMapByRoleId(roleId:number) : Observable<Map<number, UserDTO>>  {

		return this.api.post<Map<number, UserDTO>>("User/getUserMapByRoleId", roleId);
	}

	getUserList() : Observable<UserDTO[]> {

		return this.api.get<UserDTO[]>("User/getUserList");
	}

	getUserMap() : Observable<Map<number, UserDTO>> {

		return this.api.get<Map<number, UserDTO>>("User/getUserMap");
	}

	searchUserList(userDTO:UserDTO) : Observable<Array<UserDTO>> {

		return this.api.post<Array<UserDTO>>("User/searchUserList", userDTO);
	}

	searchUserMap(userDTO:UserDTO) : Observable<Map<number, UserDTO>> {

		return this.api.post<Map<number, UserDTO>>("User/searchUserMap", userDTO);
	}

	addUser(userDTO:UserDTO) : Observable<UserDTO> {

		return this.api.post<UserDTO>("User/addUser", userDTO);
	}

	addUserList(userList:Array<UserDTO>) : Observable<Array<UserDTO>> {

		return this.api.post<Array<UserDTO>>("User/addUserList", userList);
	}

	updateUser(userDTO:UserDTO) : Observable<UserDTO> {

		return this.api.post<UserDTO>("User/updateUser", userDTO);
	}

	updateUserList(userList:Array<UserDTO>) : Observable<Array<UserDTO>> {

		return this.api.post<Array<UserDTO>>("User/updateUserList", userList);
	}

	saveUser(userDTO:UserDTO) : Observable<UserDTO> {

		return this.api.post<UserDTO>("User/saveUser", userDTO);
	}

	saveUserList(userList:Array<UserDTO>) : Observable<Array<UserDTO>> {

		return this.api.post<Array<UserDTO>>("User/saveUserList", userList);
	}

	activateUserById(id:number) : Observable<number>{

		return this.api.post<number>("User/activateUserById", id);
	}

	activateUser(userDTO:UserDTO) : Observable<UserDTO> {

		return this.api.post<UserDTO>("User/activateUser", userDTO);
	}

	activateUserList(userList:Array<UserDTO>) : Observable<Array<UserDTO>> {

		return this.api.post<Array<UserDTO>>("User/activateUserList", userList);
	}

	deactivateUserById(id:number) : Observable<number> {

		return this.api.post<number>("User/deactivateUserById", id);
	}

	deactivateUser(userDTO:UserDTO) : Observable<UserDTO> {

		return this.api.post<UserDTO>("User/deactivateUser", userDTO);
	}

	deactivateUserList(userList:Array<UserDTO>) : Observable<Array<UserDTO>> {

		return this.api.post<Array<UserDTO>>("User/deactivateUserList", userList);
	}
}