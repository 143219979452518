import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/operator/catch';
import { _throw } from 'rxjs/observable/throw';
import { DataService } from './data.service';

import { of } from 'rxjs';
import { SharedService } from '../services/shared.service';

@Injectable()
export class Api {

    // url:string = "http://localhost:8080/services/";//Localhost
    url:string = "http://136.244.96.179/Fados/services/";//UAT
    //url:string = "https://fados.app/services/"  ; // Production

    @Output() event = new EventEmitter<HttpErrorResponse>();

    constructor(public http: HttpClient,
                public dataService:DataService) {

    }

    get<T>(endpoint: string) : Observable<T> {

        let headers = {headers: new HttpHeaders({"Content-Type" :  "application/json; charset=utf-8"})};

        return this.http.get<T>(this.url + endpoint, headers).catch((error:HttpErrorResponse)=>{

            SharedService.errorMessageSubject.next(error.error);

            return of<T>();
        });
    }

    post<T>(endpoint: string, body:any) : Observable<T> {

        let headers = {headers: new HttpHeaders({"Content-Type" :  "application/json; charset=utf-8"})};

        return this.http.post<T>(this.url + endpoint, body, headers).catch((error:HttpErrorResponse)=>{

            console.log("send error == " + JSON.stringify(error));

            SharedService.errorMessageSubject.next(error.error);

            return of<T>();
        });
    }

    postImage64(endpoint: string, body:string) : Observable<string> {

        return this.http.post(this.url + endpoint, body, {responseType: "text"});
    }

    download(endpoint: string, body:any, fileName:string) : void {

        this.http.post(this.url + endpoint, body, {responseType: "blob"}).subscribe((file:any)=>{

            let blob = new Blob([file]);

            var link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = fileName + ".xlsx";
            link.click();

        }, (error)=>{

            console.log(error);
        });
    }
} 