import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Api} from './api.service';
import {CustomerWishlistDTO} from '../dto/customer-wishlist-dto';
import {CustomerDTO} from '../dto/customer-dto';

@Injectable()
export class BaseCustomerWishlistService {

	constructor(public api:Api) {

	}

	getCustomerWishlistById(id:number) : Observable<CustomerWishlistDTO> {

		return this.api.post<CustomerWishlistDTO>("CustomerWishlist/getCustomerWishlistById", id);
	}

	getCustomerWishlist(customerWishlistDTO:CustomerWishlistDTO) : Observable<CustomerWishlistDTO> {

		return this.api.post<CustomerWishlistDTO>("CustomerWishlist/getCustomerWishlist", customerWishlistDTO);
	}

	getCustomerWishlistsByCustomerId(customerDTO:CustomerDTO) : Observable<CustomerDTO> {

		return this.api.post<CustomerDTO>("CustomerWishlist/getCustomerWishlistsByCustomerId", customerDTO);
	}

	getCustomerWishlistListByCustomerId(customerId:number) : Observable<Array<CustomerWishlistDTO>> {

		return this.api.post<Array<CustomerWishlistDTO>>("CustomerWishlist/getCustomerWishlistListByCustomerId", customerId);
	}

	getCustomerWishlistMapByCustomerId(customerId:number) : Observable<Map<number, CustomerWishlistDTO>>  {

		return this.api.post<Map<number, CustomerWishlistDTO>>("CustomerWishlist/getCustomerWishlistMapByCustomerId", customerId);
	}

	getCustomerWishlistList() : Observable<CustomerWishlistDTO[]> {

		return this.api.get<CustomerWishlistDTO[]>("CustomerWishlist/getCustomerWishlistList");
	}

	getCustomerWishlistMap() : Observable<Map<number, CustomerWishlistDTO>> {

		return this.api.get<Map<number, CustomerWishlistDTO>>("CustomerWishlist/getCustomerWishlistMap");
	}

	searchCustomerWishlistList(customerWishlistDTO:CustomerWishlistDTO) : Observable<Array<CustomerWishlistDTO>> {

		return this.api.post<Array<CustomerWishlistDTO>>("CustomerWishlist/searchCustomerWishlistList", customerWishlistDTO);
	}

	searchCustomerWishlistMap(customerWishlistDTO:CustomerWishlistDTO) : Observable<Map<number, CustomerWishlistDTO>> {

		return this.api.post<Map<number, CustomerWishlistDTO>>("CustomerWishlist/searchCustomerWishlistMap", customerWishlistDTO);
	}

	addCustomerWishlist(customerWishlistDTO:CustomerWishlistDTO) : Observable<CustomerWishlistDTO> {

		return this.api.post<CustomerWishlistDTO>("CustomerWishlist/addCustomerWishlist", customerWishlistDTO);
	}

	addCustomerWishlistList(customerWishlistList:Array<CustomerWishlistDTO>) : Observable<Array<CustomerWishlistDTO>> {

		return this.api.post<Array<CustomerWishlistDTO>>("CustomerWishlist/addCustomerWishlistList", customerWishlistList);
	}

	updateCustomerWishlist(customerWishlistDTO:CustomerWishlistDTO) : Observable<CustomerWishlistDTO> {

		return this.api.post<CustomerWishlistDTO>("CustomerWishlist/updateCustomerWishlist", customerWishlistDTO);
	}

	updateCustomerWishlistList(customerWishlistList:Array<CustomerWishlistDTO>) : Observable<Array<CustomerWishlistDTO>> {

		return this.api.post<Array<CustomerWishlistDTO>>("CustomerWishlist/updateCustomerWishlistList", customerWishlistList);
	}

	saveCustomerWishlist(customerWishlistDTO:CustomerWishlistDTO) : Observable<CustomerWishlistDTO> {

		return this.api.post<CustomerWishlistDTO>("CustomerWishlist/saveCustomerWishlist", customerWishlistDTO);
	}

	saveCustomerWishlistList(customerWishlistList:Array<CustomerWishlistDTO>) : Observable<Array<CustomerWishlistDTO>> {

		return this.api.post<Array<CustomerWishlistDTO>>("CustomerWishlist/saveCustomerWishlistList", customerWishlistList);
	}

	activateCustomerWishlistById(id:number) : Observable<number>{

		return this.api.post<number>("CustomerWishlist/activateCustomerWishlistById", id);
	}

	activateCustomerWishlist(customerWishlistDTO:CustomerWishlistDTO) : Observable<CustomerWishlistDTO> {

		return this.api.post<CustomerWishlistDTO>("CustomerWishlist/activateCustomerWishlist", customerWishlistDTO);
	}

	activateCustomerWishlistList(customerWishlistList:Array<CustomerWishlistDTO>) : Observable<Array<CustomerWishlistDTO>> {

		return this.api.post<Array<CustomerWishlistDTO>>("CustomerWishlist/activateCustomerWishlistList", customerWishlistList);
	}

	deactivateCustomerWishlistById(id:number) : Observable<number> {

		return this.api.post<number>("CustomerWishlist/deactivateCustomerWishlistById", id);
	}

	deactivateCustomerWishlist(customerWishlistDTO:CustomerWishlistDTO) : Observable<CustomerWishlistDTO> {

		return this.api.post<CustomerWishlistDTO>("CustomerWishlist/deactivateCustomerWishlist", customerWishlistDTO);
	}

	deactivateCustomerWishlistList(customerWishlistList:Array<CustomerWishlistDTO>) : Observable<Array<CustomerWishlistDTO>> {

		return this.api.post<Array<CustomerWishlistDTO>>("CustomerWishlist/deactivateCustomerWishlistList", customerWishlistList);
	}
}