import {Injectable} from '@angular/core';
import {Api} from '../base-service/api.service';

import { BaseRestaurantService } from '../base-service/base-restaurant.service';
import { RestaurantDTO } from '../dto/restaurant-dto';
import { Observable } from 'rxjs';
import { CustomerOfferDTO } from '../dto/customer-offer-dto';
import { RestaurantUpdateRequestDTO } from '../dto/restaurant-update-request-dto';

@Injectable()
export class RestaurantService extends BaseRestaurantService{

	constructor(public api:Api) {
		super(api);
	}

	addRestaurant(restaurantDTO:RestaurantDTO) : Observable<any> {

		return this.api.post<RestaurantDTO>("RestaurantService/addRestaurant", restaurantDTO);
	}

	updateRestaurant(restaurantDTO:RestaurantDTO) : Observable<any> {

		return this.api.post<RestaurantDTO>("RestaurantService/updateRestaurant", restaurantDTO);
	}

	
	checkSupplierHaveSubmitedRequest(supplierId:number) : Observable<any> {

		return this.api.get<RestaurantUpdateRequestDTO>("RestaurantService/checkSupplierHaveSubmitedRequest/"+supplierId);
	}
	addRestaurantUpdateRequest(restaurantDTO:RestaurantDTO) : Observable<RestaurantUpdateRequestDTO> {

		return this.api.post<RestaurantUpdateRequestDTO>("RestaurantService/addRestaurantUpdateRequest", restaurantDTO);
	}

	approveRestaurantUpdateRequest(restaurantUpdateRequestDTO:RestaurantUpdateRequestDTO) : Observable<any> {

		return this.api.post<Boolean>("RestaurantService/approveRestaurantUpdateRequest", restaurantUpdateRequestDTO);
	}

	rejectRestaurantUpdateRequest(restaurantUpdateRequestDTO:RestaurantUpdateRequestDTO) : Observable<any> {

		return this.api.post<Boolean>("RestaurantService/rejectRestaurantUpdateRequest", restaurantUpdateRequestDTO);
	}
	
	
	getRestaurantSpecificationsLists(restaurantId:number) : Observable<RestaurantDTO> {
		
		return this.api.post<RestaurantDTO>("RestaurantService/getRestaurantSpecificationsLists/"+restaurantId ,restaurantId);

	}

	consumeCustomerOffer(customerOfferDTO:CustomerOfferDTO) : Observable<number> {
		
		return this.api.post<number>("RestaurantService/consumeCustomerOffer" ,customerOfferDTO);

	}

	searchSupplierCustomerOfferList(customerOfferDTO:CustomerOfferDTO) : Observable<Array<CustomerOfferDTO>> {

		return this.api.post<Array<CustomerOfferDTO>>("RestaurantService/searchSupplierCustomerOfferList", customerOfferDTO);
	}

	getRestaurantLogoById(restaurantId:number) : Observable<RestaurantDTO> {

		return this.api.get<any>("RestaurantService/getRestaurantLogoById/"+restaurantId);
	}

	getRestaurantBasicInfoById(restaurantId:number) : Observable<RestaurantDTO> {

		return this.api.get<any>("RestaurantService/getRestaurantBasicInfoById/"+restaurantId);
	}

	getRestaurantUpdateRequestById(id:number) : Observable<RestaurantUpdateRequestDTO> {

		return this.api.get<RestaurantUpdateRequestDTO>("RestaurantService/getRestaurantUpdateRequestById/"+id);
	}
	
	
}