import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';
import { ApprovalComponent } from './views/approval/approval.component';
import { ForgotPasswordComponent } from './views/change-forgot-password/forgotPasswordComponent.component';

export const routes: Routes = [
  {
      path: '',
      redirectTo: 'dashboard',
      pathMatch: 'full',
    },
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login Page'
    }
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    data: {
      title: 'Forgot Password Page'
    }
  },
  {
    path: 'register',
    component: RegisterComponent,
    data: {
      title: 'Register Page'
    }
  },
  {
    path: 'approval',
    component: ApprovalComponent,
    data: {
      title: 'Approval Page'
    }
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: 'Home'
    },
    children: [
      //Fados settings
      {
        path: 'change-password',
        loadChildren: () => import('./views/change-forgot-password/password.managment.module').then(m => m.PasswordManagmentModule)
      },
      {
        path: 'users',
        loadChildren: () => import('./views/users/users.module').then(m => m.UsersModule)
      },
      {
        path: 'offers',
        loadChildren: () => import('./views/offers/offers.module').then(m => m.OffersModule)
      },
      {
        path: 'settings',
        loadChildren: () => import('./views/general-settings/general-settings.module').then(m => m.GeneralSettingsModule)
      },
      {
        path: 'restaurant',
        loadChildren: () => import('./views/restaurant-settings/restaurant-settings.module').then(m => m.restaurantSettingsModule)
      },
      {
        path: 'supplier-settings',
        loadChildren: () => import('./views/suppliers-settings/supplier-settings.module').then(m => m.SuppliersSettingsModule)
      },
      {
        path: 'supplier-admin',
        loadChildren: () => import('./views/suppliers-admin/supplier-admin.module').then(m => m.SupplierAdminModule)
      },
      {
        path: 'supplier-employee',
        loadChildren: () => import('./views/suppliers-employee/supplier-employee.module').then(m => m.SupplierEmployeeModule)
      },
      //Fados settings end
      {
        path: 'base',
        loadChildren: () => import('./views/base/base.module').then(m => m.BaseModule)
      },
      {
        path: 'buttons',
        loadChildren: () => import('./views/buttons/buttons.module').then(m => m.ButtonsModule)
      },
      {
        path: 'charts',
        loadChildren: () => import('./views/chartjs/chartjs.module').then(m => m.ChartJSModule)
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'icons',
        loadChildren: () => import('./views/icons/icons.module').then(m => m.IconsModule)
      },
      {
        path: 'notifications',
        loadChildren: () => import('./views/notifications/notifications.module').then(m => m.NotificationsModule)
      },
      {
        path: 'theme',
        loadChildren: () => import('./views/theme/theme.module').then(m => m.ThemeModule)
      },
      {
        path: 'widgets',
        loadChildren: () => import('./views/widgets/widgets.module').then(m => m.WidgetsModule)
      }
    ]
  },
  { path: '**', component: P404Component }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
