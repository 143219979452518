import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Api} from './api.service';
import {TcodeCuisineDTO} from '../dto/tcode-cuisine-dto';

@Injectable()
export class BaseTcodeCuisineService {

	constructor(public api:Api) {

	}

	getTcodeCuisineById(id:number) : Observable<TcodeCuisineDTO> {

		return this.api.post<TcodeCuisineDTO>("TcodeCuisine/getTcodeCuisineById", id);
	}

	getTcodeCuisine(tcodeCuisineDTO:TcodeCuisineDTO) : Observable<TcodeCuisineDTO> {

		return this.api.post<TcodeCuisineDTO>("TcodeCuisine/getTcodeCuisine", tcodeCuisineDTO);
	}

	getTcodeCuisineList() : Observable<TcodeCuisineDTO[]> {

		return this.api.get<TcodeCuisineDTO[]>("TcodeCuisine/getTcodeCuisineList");
	}

	getTcodeCuisineMap() : Observable<Map<number, TcodeCuisineDTO>> {

		return this.api.get<Map<number, TcodeCuisineDTO>>("TcodeCuisine/getTcodeCuisineMap");
	}

	searchTcodeCuisineList(tcodeCuisineDTO:TcodeCuisineDTO) : Observable<Array<TcodeCuisineDTO>> {

		return this.api.post<Array<TcodeCuisineDTO>>("TcodeCuisine/searchTcodeCuisineList", tcodeCuisineDTO);
	}

	searchTcodeCuisineMap(tcodeCuisineDTO:TcodeCuisineDTO) : Observable<Map<number, TcodeCuisineDTO>> {

		return this.api.post<Map<number, TcodeCuisineDTO>>("TcodeCuisine/searchTcodeCuisineMap", tcodeCuisineDTO);
	}

	addTcodeCuisine(tcodeCuisineDTO:TcodeCuisineDTO) : Observable<TcodeCuisineDTO> {

		return this.api.post<TcodeCuisineDTO>("TcodeCuisine/addTcodeCuisine", tcodeCuisineDTO);
	}

	addTcodeCuisineList(tcodeCuisineList:Array<TcodeCuisineDTO>) : Observable<Array<TcodeCuisineDTO>> {

		return this.api.post<Array<TcodeCuisineDTO>>("TcodeCuisine/addTcodeCuisineList", tcodeCuisineList);
	}

	updateTcodeCuisine(tcodeCuisineDTO:TcodeCuisineDTO) : Observable<TcodeCuisineDTO> {

		return this.api.post<TcodeCuisineDTO>("TcodeCuisine/updateTcodeCuisine", tcodeCuisineDTO);
	}

	updateTcodeCuisineList(tcodeCuisineList:Array<TcodeCuisineDTO>) : Observable<Array<TcodeCuisineDTO>> {

		return this.api.post<Array<TcodeCuisineDTO>>("TcodeCuisine/updateTcodeCuisineList", tcodeCuisineList);
	}

	saveTcodeCuisine(tcodeCuisineDTO:TcodeCuisineDTO) : Observable<TcodeCuisineDTO> {

		return this.api.post<TcodeCuisineDTO>("TcodeCuisine/saveTcodeCuisine", tcodeCuisineDTO);
	}

	saveTcodeCuisineList(tcodeCuisineList:Array<TcodeCuisineDTO>) : Observable<Array<TcodeCuisineDTO>> {

		return this.api.post<Array<TcodeCuisineDTO>>("TcodeCuisine/saveTcodeCuisineList", tcodeCuisineList);
	}

	activateTcodeCuisineById(id:number) : Observable<number>{

		return this.api.post<number>("TcodeCuisine/activateTcodeCuisineById", id);
	}

	activateTcodeCuisine(tcodeCuisineDTO:TcodeCuisineDTO) : Observable<TcodeCuisineDTO> {

		return this.api.post<TcodeCuisineDTO>("TcodeCuisine/activateTcodeCuisine", tcodeCuisineDTO);
	}

	activateTcodeCuisineList(tcodeCuisineList:Array<TcodeCuisineDTO>) : Observable<Array<TcodeCuisineDTO>> {

		return this.api.post<Array<TcodeCuisineDTO>>("TcodeCuisine/activateTcodeCuisineList", tcodeCuisineList);
	}

	deactivateTcodeCuisineById(id:number) : Observable<number> {

		return this.api.post<number>("TcodeCuisine/deactivateTcodeCuisineById", id);
	}

	deactivateTcodeCuisine(tcodeCuisineDTO:TcodeCuisineDTO) : Observable<TcodeCuisineDTO> {

		return this.api.post<TcodeCuisineDTO>("TcodeCuisine/deactivateTcodeCuisine", tcodeCuisineDTO);
	}

	deactivateTcodeCuisineList(tcodeCuisineList:Array<TcodeCuisineDTO>) : Observable<Array<TcodeCuisineDTO>> {

		return this.api.post<Array<TcodeCuisineDTO>>("TcodeCuisine/deactivateTcodeCuisineList", tcodeCuisineList);
	}
}