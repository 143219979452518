import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Api} from '../base-service/api.service';
import { BaseCustomerService } from '../base-service/base-customer.service';
import { CustomerDTO } from '../dto/customer-dto';

@Injectable()
export class CustomerService extends BaseCustomerService{

	constructor(public api:Api) {
		super(api);
	}


}