import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Api} from './api.service';
import {TcodeCityDTO} from '../dto/tcode-city-dto';
import {TcodeCountryDTO} from '../dto/tcode-country-dto';

@Injectable()
export class BaseTcodeCityService {

	constructor(public api:Api) {

	}

	getTcodeCityById(id:number) : Observable<TcodeCityDTO> {

		return this.api.post<TcodeCityDTO>("TcodeCity/getTcodeCityById", id);
	}

	getTcodeCity(tcodeCityDTO:TcodeCityDTO) : Observable<TcodeCityDTO> {

		return this.api.post<TcodeCityDTO>("TcodeCity/getTcodeCity", tcodeCityDTO);
	}

	getTcodeCitysByCountryId(tcodeCountryDTO:TcodeCountryDTO) : Observable<TcodeCountryDTO> {

		return this.api.post<TcodeCountryDTO>("TcodeCity/getTcodeCitysByCountryId", tcodeCountryDTO);
	}

	getTcodeCityListByCountryId(countryId:number) : Observable<Array<TcodeCityDTO>> {

		return this.api.post<Array<TcodeCityDTO>>("TcodeCity/getTcodeCityListByCountryId", countryId);
	}

	getTcodeCityMapByCountryId(countryId:number) : Observable<Map<number, TcodeCityDTO>>  {

		return this.api.post<Map<number, TcodeCityDTO>>("TcodeCity/getTcodeCityMapByCountryId", countryId);
	}

	getTcodeCityList() : Observable<TcodeCityDTO[]> {

		return this.api.get<TcodeCityDTO[]>("TcodeCity/getTcodeCityList");
	}

	getTcodeCityMap() : Observable<Map<number, TcodeCityDTO>> {

		return this.api.get<Map<number, TcodeCityDTO>>("TcodeCity/getTcodeCityMap");
	}

	searchTcodeCityList(tcodeCityDTO:TcodeCityDTO) : Observable<Array<TcodeCityDTO>> {

		return this.api.post<Array<TcodeCityDTO>>("TcodeCity/searchTcodeCityList", tcodeCityDTO);
	}

	searchTcodeCityMap(tcodeCityDTO:TcodeCityDTO) : Observable<Map<number, TcodeCityDTO>> {

		return this.api.post<Map<number, TcodeCityDTO>>("TcodeCity/searchTcodeCityMap", tcodeCityDTO);
	}

	addTcodeCity(tcodeCityDTO:TcodeCityDTO) : Observable<TcodeCityDTO> {

		return this.api.post<TcodeCityDTO>("TcodeCity/addTcodeCity", tcodeCityDTO);
	}

	addTcodeCityList(tcodeCityList:Array<TcodeCityDTO>) : Observable<Array<TcodeCityDTO>> {

		return this.api.post<Array<TcodeCityDTO>>("TcodeCity/addTcodeCityList", tcodeCityList);
	}

	updateTcodeCity(tcodeCityDTO:TcodeCityDTO) : Observable<TcodeCityDTO> {

		return this.api.post<TcodeCityDTO>("TcodeCity/updateTcodeCity", tcodeCityDTO);
	}

	updateTcodeCityList(tcodeCityList:Array<TcodeCityDTO>) : Observable<Array<TcodeCityDTO>> {

		return this.api.post<Array<TcodeCityDTO>>("TcodeCity/updateTcodeCityList", tcodeCityList);
	}

	saveTcodeCity(tcodeCityDTO:TcodeCityDTO) : Observable<TcodeCityDTO> {

		return this.api.post<TcodeCityDTO>("TcodeCity/saveTcodeCity", tcodeCityDTO);
	}

	saveTcodeCityList(tcodeCityList:Array<TcodeCityDTO>) : Observable<Array<TcodeCityDTO>> {

		return this.api.post<Array<TcodeCityDTO>>("TcodeCity/saveTcodeCityList", tcodeCityList);
	}

	activateTcodeCityById(id:number) : Observable<number>{

		return this.api.post<number>("TcodeCity/activateTcodeCityById", id);
	}

	activateTcodeCity(tcodeCityDTO:TcodeCityDTO) : Observable<TcodeCityDTO> {

		return this.api.post<TcodeCityDTO>("TcodeCity/activateTcodeCity", tcodeCityDTO);
	}

	activateTcodeCityList(tcodeCityList:Array<TcodeCityDTO>) : Observable<Array<TcodeCityDTO>> {

		return this.api.post<Array<TcodeCityDTO>>("TcodeCity/activateTcodeCityList", tcodeCityList);
	}

	deactivateTcodeCityById(id:number) : Observable<number> {

		return this.api.post<number>("TcodeCity/deactivateTcodeCityById", id);
	}

	deactivateTcodeCity(tcodeCityDTO:TcodeCityDTO) : Observable<TcodeCityDTO> {

		return this.api.post<TcodeCityDTO>("TcodeCity/deactivateTcodeCity", tcodeCityDTO);
	}

	deactivateTcodeCityList(tcodeCityList:Array<TcodeCityDTO>) : Observable<Array<TcodeCityDTO>> {

		return this.api.post<Array<TcodeCityDTO>>("TcodeCity/deactivateTcodeCityList", tcodeCityList);
	}
}