import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {CustomerDTO} from '../dto/customer-dto';
import {CustomerCuisinesDTO} from '../dto/customer-cuisines-dto';
import {CustomerFriendsDTO} from '../dto/customer-friends-dto';
import {CustomerOfferDTO} from '../dto/customer-offer-dto';
import {CustomerWishlistDTO} from '../dto/customer-wishlist-dto';
import {DeviceDTO} from '../dto/device-dto';
import {LoginHistDTO} from '../dto/login-hist-dto';
import {OfferDTO} from '../dto/offer-dto';
import {ParameterDTO} from '../dto/parameter-dto';
import {RestReturnCustomerDTO} from '../dto/rest-return-customer-dto';
import {RestaurantDTO} from '../dto/restaurant-dto';
import {RestaurantCuisinesDTO} from '../dto/restaurant-cuisines-dto';
import {RestaurantDesignDTO} from '../dto/restaurant-design-dto';
import {RestaurantDestinationDTO} from '../dto/restaurant-destination-dto';
import {RestaurantEnvironmentDTO} from '../dto/restaurant-environment-dto';
import {RestaurantRateDTO} from '../dto/restaurant-rate-dto';
import {RestaurantSpecialityDTO} from '../dto/restaurant-speciality-dto';
import {RoleDTO} from '../dto/role-dto';
import {SupplierDTO} from '../dto/supplier-dto';
import {TcodeCityDTO} from '../dto/tcode-city-dto';
import {TcodeCountryDTO} from '../dto/tcode-country-dto';
import {TcodeCuisineDTO} from '../dto/tcode-cuisine-dto';
import {TcodeOccupationDTO} from '../dto/tcode-occupation-dto';
import {TcodeRejectionReasonDTO} from '../dto/tcode-rejection-reason-dto';
import {TcodeRestDesignDTO} from '../dto/tcode-rest-design-dto';
import {TcodeRestDestinationDTO} from '../dto/tcode-rest-destination-dto';
import {TcodeRestEnvironmentDTO} from '../dto/tcode-rest-environment-dto';
import {TcodeSpecialityDTO} from '../dto/tcode-speciality-dto';
import {TcodeStatusDTO} from '../dto/tcode-status-dto';
import {TcodeSupplierTypeDTO} from '../dto/tcode-supplier-type-dto';
import {UserDTO} from '../dto/user-dto';

@Injectable()
export class DataService {

	customerDTO:CustomerDTO = new CustomerDTO();
	customerCuisinesDTO:CustomerCuisinesDTO = new CustomerCuisinesDTO();
	customerFriendsDTO:CustomerFriendsDTO = new CustomerFriendsDTO();
	customerOfferDTO:CustomerOfferDTO = new CustomerOfferDTO();
	customerWishlistDTO:CustomerWishlistDTO = new CustomerWishlistDTO();
	deviceDTO:DeviceDTO = new DeviceDTO();
	loginHistDTO:LoginHistDTO = new LoginHistDTO();
	offerDTO:OfferDTO = new OfferDTO();
	parameterDTO:ParameterDTO = new ParameterDTO();
	restReturnCustomerDTO:RestReturnCustomerDTO = new RestReturnCustomerDTO();
	restaurantDTO:RestaurantDTO = new RestaurantDTO();
	restaurantCuisinesDTO:RestaurantCuisinesDTO = new RestaurantCuisinesDTO();
	restaurantDesignDTO:RestaurantDesignDTO = new RestaurantDesignDTO();
	restaurantDestinationDTO:RestaurantDestinationDTO = new RestaurantDestinationDTO();
	restaurantEnvironmentDTO:RestaurantEnvironmentDTO = new RestaurantEnvironmentDTO();
	restaurantRateDTO:RestaurantRateDTO = new RestaurantRateDTO();
	restaurantSpecialityDTO:RestaurantSpecialityDTO = new RestaurantSpecialityDTO();
	roleDTO:RoleDTO = new RoleDTO();
	supplierDTO:SupplierDTO = new SupplierDTO();
	tcodeCityDTO:TcodeCityDTO = new TcodeCityDTO();
	tcodeCountryDTO:TcodeCountryDTO = new TcodeCountryDTO();
	tcodeCuisineDTO:TcodeCuisineDTO = new TcodeCuisineDTO();
	tcodeOccupationDTO:TcodeOccupationDTO = new TcodeOccupationDTO();
	tcodeRejectionReasonDTO:TcodeRejectionReasonDTO = new TcodeRejectionReasonDTO();
	tcodeRestDesignDTO:TcodeRestDesignDTO = new TcodeRestDesignDTO();
	tcodeRestDestinationDTO:TcodeRestDestinationDTO = new TcodeRestDestinationDTO();
	tcodeRestEnvironmentDTO:TcodeRestEnvironmentDTO = new TcodeRestEnvironmentDTO();
	tcodeSpecialityDTO:TcodeSpecialityDTO = new TcodeSpecialityDTO();
	tcodeStatusDTO:TcodeStatusDTO = new TcodeStatusDTO();
	tcodeSupplierTypeDTO:TcodeSupplierTypeDTO = new TcodeSupplierTypeDTO();
	userDTO:UserDTO = new UserDTO();

	customerList:Array<CustomerDTO> = new Array<CustomerDTO>();
	customerCuisinesList:Array<CustomerCuisinesDTO> = new Array<CustomerCuisinesDTO>();
	customerFriendsList:Array<CustomerFriendsDTO> = new Array<CustomerFriendsDTO>();
	customerOfferList:Array<CustomerOfferDTO> = new Array<CustomerOfferDTO>();
	customerWishlistList:Array<CustomerWishlistDTO> = new Array<CustomerWishlistDTO>();
	deviceList:Array<DeviceDTO> = new Array<DeviceDTO>();
	loginHistList:Array<LoginHistDTO> = new Array<LoginHistDTO>();
	offerList:Array<OfferDTO> = new Array<OfferDTO>();
	parameterList:Array<ParameterDTO> = new Array<ParameterDTO>();
	restReturnCustomerList:Array<RestReturnCustomerDTO> = new Array<RestReturnCustomerDTO>();
	restaurantList:Array<RestaurantDTO> = new Array<RestaurantDTO>();
	restaurantCuisinesList:Array<RestaurantCuisinesDTO> = new Array<RestaurantCuisinesDTO>();
	restaurantDesignList:Array<RestaurantDesignDTO> = new Array<RestaurantDesignDTO>();
	restaurantDestinationList:Array<RestaurantDestinationDTO> = new Array<RestaurantDestinationDTO>();
	restaurantEnvironmentList:Array<RestaurantEnvironmentDTO> = new Array<RestaurantEnvironmentDTO>();
	restaurantRateList:Array<RestaurantRateDTO> = new Array<RestaurantRateDTO>();
	restaurantSpecialityList:Array<RestaurantSpecialityDTO> = new Array<RestaurantSpecialityDTO>();
	roleList:Array<RoleDTO> = new Array<RoleDTO>();
	supplierList:Array<SupplierDTO> = new Array<SupplierDTO>();
	tcodeCityList:Array<TcodeCityDTO> = new Array<TcodeCityDTO>();
	tcodeCountryList:Array<TcodeCountryDTO> = new Array<TcodeCountryDTO>();
	tcodeCuisineList:Array<TcodeCuisineDTO> = new Array<TcodeCuisineDTO>();
	tcodeOccupationList:Array<TcodeOccupationDTO> = new Array<TcodeOccupationDTO>();
	tcodeRejectionReasonList:Array<TcodeRejectionReasonDTO> = new Array<TcodeRejectionReasonDTO>();
	tcodeRestDesignList:Array<TcodeRestDesignDTO> = new Array<TcodeRestDesignDTO>();
	tcodeRestDestinationList:Array<TcodeRestDestinationDTO> = new Array<TcodeRestDestinationDTO>();
	tcodeRestEnvironmentList:Array<TcodeRestEnvironmentDTO> = new Array<TcodeRestEnvironmentDTO>();
	tcodeSpecialityList:Array<TcodeSpecialityDTO> = new Array<TcodeSpecialityDTO>();
	tcodeStatusList:Array<TcodeStatusDTO> = new Array<TcodeStatusDTO>();
	tcodeSupplierTypeList:Array<TcodeSupplierTypeDTO> = new Array<TcodeSupplierTypeDTO>();
	userList:Array<UserDTO> = new Array<UserDTO>();

	customerMap:Map<number, CustomerDTO> = new Map<number, CustomerDTO>();
	customerCuisinesMap:Map<number, CustomerCuisinesDTO> = new Map<number, CustomerCuisinesDTO>();
	customerFriendsMap:Map<number, CustomerFriendsDTO> = new Map<number, CustomerFriendsDTO>();
	customerOfferMap:Map<number, CustomerOfferDTO> = new Map<number, CustomerOfferDTO>();
	customerWishlistMap:Map<number, CustomerWishlistDTO> = new Map<number, CustomerWishlistDTO>();
	deviceMap:Map<number, DeviceDTO> = new Map<number, DeviceDTO>();
	loginHistMap:Map<number, LoginHistDTO> = new Map<number, LoginHistDTO>();
	offerMap:Map<number, OfferDTO> = new Map<number, OfferDTO>();
	parameterMap:Map<number, ParameterDTO> = new Map<number, ParameterDTO>();
	restReturnCustomerMap:Map<number, RestReturnCustomerDTO> = new Map<number, RestReturnCustomerDTO>();
	restaurantMap:Map<number, RestaurantDTO> = new Map<number, RestaurantDTO>();
	restaurantCuisinesMap:Map<number, RestaurantCuisinesDTO> = new Map<number, RestaurantCuisinesDTO>();
	restaurantDesignMap:Map<number, RestaurantDesignDTO> = new Map<number, RestaurantDesignDTO>();
	restaurantDestinationMap:Map<number, RestaurantDestinationDTO> = new Map<number, RestaurantDestinationDTO>();
	restaurantEnvironmentMap:Map<number, RestaurantEnvironmentDTO> = new Map<number, RestaurantEnvironmentDTO>();
	restaurantRateMap:Map<number, RestaurantRateDTO> = new Map<number, RestaurantRateDTO>();
	restaurantSpecialityMap:Map<number, RestaurantSpecialityDTO> = new Map<number, RestaurantSpecialityDTO>();
	roleMap:Map<number, RoleDTO> = new Map<number, RoleDTO>();
	supplierMap:Map<number, SupplierDTO> = new Map<number, SupplierDTO>();
	tcodeCityMap:Map<number, TcodeCityDTO> = new Map<number, TcodeCityDTO>();
	tcodeCountryMap:Map<number, TcodeCountryDTO> = new Map<number, TcodeCountryDTO>();
	tcodeCuisineMap:Map<number, TcodeCuisineDTO> = new Map<number, TcodeCuisineDTO>();
	tcodeOccupationMap:Map<number, TcodeOccupationDTO> = new Map<number, TcodeOccupationDTO>();
	tcodeRejectionReasonMap:Map<number, TcodeRejectionReasonDTO> = new Map<number, TcodeRejectionReasonDTO>();
	tcodeRestDesignMap:Map<number, TcodeRestDesignDTO> = new Map<number, TcodeRestDesignDTO>();
	tcodeRestDestinationMap:Map<number, TcodeRestDestinationDTO> = new Map<number, TcodeRestDestinationDTO>();
	tcodeRestEnvironmentMap:Map<number, TcodeRestEnvironmentDTO> = new Map<number, TcodeRestEnvironmentDTO>();
	tcodeSpecialityMap:Map<number, TcodeSpecialityDTO> = new Map<number, TcodeSpecialityDTO>();
	tcodeStatusMap:Map<number, TcodeStatusDTO> = new Map<number, TcodeStatusDTO>();
	tcodeSupplierTypeMap:Map<number, TcodeSupplierTypeDTO> = new Map<number, TcodeSupplierTypeDTO>();
	userMap:Map<number, UserDTO> = new Map<number, UserDTO>();
}