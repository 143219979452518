import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Api} from './api.service';
import {TcodeRestDestinationDTO} from '../dto/tcode-rest-destination-dto';

@Injectable()
export class BaseTcodeRestDestinationService {

	constructor(public api:Api) {

	}

	getTcodeRestDestinationById(id:number) : Observable<TcodeRestDestinationDTO> {

		return this.api.post<TcodeRestDestinationDTO>("TcodeRestDestination/getTcodeRestDestinationById", id);
	}

	getTcodeRestDestination(tcodeRestDestinationDTO:TcodeRestDestinationDTO) : Observable<TcodeRestDestinationDTO> {

		return this.api.post<TcodeRestDestinationDTO>("TcodeRestDestination/getTcodeRestDestination", tcodeRestDestinationDTO);
	}

	getTcodeRestDestinationList() : Observable<TcodeRestDestinationDTO[]> {

		return this.api.get<TcodeRestDestinationDTO[]>("TcodeRestDestination/getTcodeRestDestinationList");
	}

	getTcodeRestDestinationMap() : Observable<Map<number, TcodeRestDestinationDTO>> {

		return this.api.get<Map<number, TcodeRestDestinationDTO>>("TcodeRestDestination/getTcodeRestDestinationMap");
	}

	searchTcodeRestDestinationList(tcodeRestDestinationDTO:TcodeRestDestinationDTO) : Observable<Array<TcodeRestDestinationDTO>> {

		return this.api.post<Array<TcodeRestDestinationDTO>>("TcodeRestDestination/searchTcodeRestDestinationList", tcodeRestDestinationDTO);
	}

	searchTcodeRestDestinationMap(tcodeRestDestinationDTO:TcodeRestDestinationDTO) : Observable<Map<number, TcodeRestDestinationDTO>> {

		return this.api.post<Map<number, TcodeRestDestinationDTO>>("TcodeRestDestination/searchTcodeRestDestinationMap", tcodeRestDestinationDTO);
	}

	addTcodeRestDestination(tcodeRestDestinationDTO:TcodeRestDestinationDTO) : Observable<TcodeRestDestinationDTO> {

		return this.api.post<TcodeRestDestinationDTO>("TcodeRestDestination/addTcodeRestDestination", tcodeRestDestinationDTO);
	}

	addTcodeRestDestinationList(tcodeRestDestinationList:Array<TcodeRestDestinationDTO>) : Observable<Array<TcodeRestDestinationDTO>> {

		return this.api.post<Array<TcodeRestDestinationDTO>>("TcodeRestDestination/addTcodeRestDestinationList", tcodeRestDestinationList);
	}

	updateTcodeRestDestination(tcodeRestDestinationDTO:TcodeRestDestinationDTO) : Observable<TcodeRestDestinationDTO> {

		return this.api.post<TcodeRestDestinationDTO>("TcodeRestDestination/updateTcodeRestDestination", tcodeRestDestinationDTO);
	}

	updateTcodeRestDestinationList(tcodeRestDestinationList:Array<TcodeRestDestinationDTO>) : Observable<Array<TcodeRestDestinationDTO>> {

		return this.api.post<Array<TcodeRestDestinationDTO>>("TcodeRestDestination/updateTcodeRestDestinationList", tcodeRestDestinationList);
	}

	saveTcodeRestDestination(tcodeRestDestinationDTO:TcodeRestDestinationDTO) : Observable<TcodeRestDestinationDTO> {

		return this.api.post<TcodeRestDestinationDTO>("TcodeRestDestination/saveTcodeRestDestination", tcodeRestDestinationDTO);
	}

	saveTcodeRestDestinationList(tcodeRestDestinationList:Array<TcodeRestDestinationDTO>) : Observable<Array<TcodeRestDestinationDTO>> {

		return this.api.post<Array<TcodeRestDestinationDTO>>("TcodeRestDestination/saveTcodeRestDestinationList", tcodeRestDestinationList);
	}

	activateTcodeRestDestinationById(id:number) : Observable<number>{

		return this.api.post<number>("TcodeRestDestination/activateTcodeRestDestinationById", id);
	}

	activateTcodeRestDestination(tcodeRestDestinationDTO:TcodeRestDestinationDTO) : Observable<TcodeRestDestinationDTO> {

		return this.api.post<TcodeRestDestinationDTO>("TcodeRestDestination/activateTcodeRestDestination", tcodeRestDestinationDTO);
	}

	activateTcodeRestDestinationList(tcodeRestDestinationList:Array<TcodeRestDestinationDTO>) : Observable<Array<TcodeRestDestinationDTO>> {

		return this.api.post<Array<TcodeRestDestinationDTO>>("TcodeRestDestination/activateTcodeRestDestinationList", tcodeRestDestinationList);
	}

	deactivateTcodeRestDestinationById(id:number) : Observable<number> {

		return this.api.post<number>("TcodeRestDestination/deactivateTcodeRestDestinationById", id);
	}

	deactivateTcodeRestDestination(tcodeRestDestinationDTO:TcodeRestDestinationDTO) : Observable<TcodeRestDestinationDTO> {

		return this.api.post<TcodeRestDestinationDTO>("TcodeRestDestination/deactivateTcodeRestDestination", tcodeRestDestinationDTO);
	}

	deactivateTcodeRestDestinationList(tcodeRestDestinationList:Array<TcodeRestDestinationDTO>) : Observable<Array<TcodeRestDestinationDTO>> {

		return this.api.post<Array<TcodeRestDestinationDTO>>("TcodeRestDestination/deactivateTcodeRestDestinationList", tcodeRestDestinationList);
	}
}