import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Api} from '../base-service/api.service';
import { BaseCustomerService } from '../base-service/base-customer.service';
import { UserDTO } from '../dto/user-dto';

@Injectable()
export class AdminService {

	constructor(public api:Api) {

	}

	loginAdmin(userDTO:UserDTO) : Observable<Array<UserDTO>> {

		return this.api.post<Array<UserDTO>>("AdminService/loginAdmin", userDTO);
	}

	getSupplierOffersStatistics(supplierId:number) : Observable<any> {

		return this.api.get<any>("AdminService/getSupplierOffersStatistics/"+supplierId);
	}

	getFadosAdminStatistics() : Observable<any> {

		return this.api.get<any>("AdminService/getFadosAdminStatistics");
	}

	checkSupplierApproveTerms(supplierId:number) : Observable<any> {

		return this.api.get<Boolean>("AdminService/checkSupplierApproveTerms/"+supplierId);
	}

	checkSupplierEmailExistance(userDTO :UserDTO) : Observable<any> {

		return this.api.post<Boolean>("AdminService/checkSupplierEmailExistance/",userDTO);
	}

	checkSupplierAlreadyHaveUser(userDTO :UserDTO) : Observable<any> {

		return this.api.post<Boolean>("AdminService/checkSupplierAlreadyHaveUser/",userDTO);
	}

	
	
	saveSupplierApproveTerms(supplierId:number) : Observable<any> {

		return this.api.post<any>("AdminService/saveSupplierApproveTerms/",supplierId);
	}
	changePassword(userDTO:UserDTO) : Observable<any> {

		return this.api.post<any>("AdminService/changePassword/",userDTO);
	}

	checkCurrentPasswordByUserId(userDTO:UserDTO) : Observable<any> {

		return this.api.post<any>("AdminService/verifyPwdByUserId/",userDTO);
	}
	
	resetPassword(userDTO:UserDTO) : Observable<any> {

		return this.api.post<any>("AdminService/resetPassword/",userDTO);
	}
	

	


}