import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Api} from './api.service';
import {ParameterDTO} from '../dto/parameter-dto';

@Injectable()
export class BaseParameterService {

	constructor(public api:Api) {

	}

	getParameterById(id:number) : Observable<ParameterDTO> {

		return this.api.post<ParameterDTO>("Parameter/getParameterById", id);
	}

	getParameter(parameterDTO:ParameterDTO) : Observable<ParameterDTO> {

		return this.api.post<ParameterDTO>("Parameter/getParameter", parameterDTO);
	}

	getParameterList() : Observable<ParameterDTO[]> {

		return this.api.get<ParameterDTO[]>("Parameter/getParameterList");
	}

	getParameterMap() : Observable<Map<number, ParameterDTO>> {

		return this.api.get<Map<number, ParameterDTO>>("Parameter/getParameterMap");
	}

	searchParameterList(parameterDTO:ParameterDTO) : Observable<Array<ParameterDTO>> {

		return this.api.post<Array<ParameterDTO>>("Parameter/searchParameterList", parameterDTO);
	}

	searchParameterMap(parameterDTO:ParameterDTO) : Observable<Map<number, ParameterDTO>> {

		return this.api.post<Map<number, ParameterDTO>>("Parameter/searchParameterMap", parameterDTO);
	}

	addParameter(parameterDTO:ParameterDTO) : Observable<ParameterDTO> {

		return this.api.post<ParameterDTO>("Parameter/addParameter", parameterDTO);
	}

	addParameterList(parameterList:Array<ParameterDTO>) : Observable<Array<ParameterDTO>> {

		return this.api.post<Array<ParameterDTO>>("Parameter/addParameterList", parameterList);
	}

	updateParameter(parameterDTO:ParameterDTO) : Observable<ParameterDTO> {

		return this.api.post<ParameterDTO>("Parameter/updateParameter", parameterDTO);
	}

	updateParameterList(parameterList:Array<ParameterDTO>) : Observable<Array<ParameterDTO>> {

		return this.api.post<Array<ParameterDTO>>("Parameter/updateParameterList", parameterList);
	}

	saveParameter(parameterDTO:ParameterDTO) : Observable<ParameterDTO> {

		return this.api.post<ParameterDTO>("Parameter/saveParameter", parameterDTO);
	}

	saveParameterList(parameterList:Array<ParameterDTO>) : Observable<Array<ParameterDTO>> {

		return this.api.post<Array<ParameterDTO>>("Parameter/saveParameterList", parameterList);
	}

	activateParameterById(id:number) : Observable<number>{

		return this.api.post<number>("Parameter/activateParameterById", id);
	}

	activateParameter(parameterDTO:ParameterDTO) : Observable<ParameterDTO> {

		return this.api.post<ParameterDTO>("Parameter/activateParameter", parameterDTO);
	}

	activateParameterList(parameterList:Array<ParameterDTO>) : Observable<Array<ParameterDTO>> {

		return this.api.post<Array<ParameterDTO>>("Parameter/activateParameterList", parameterList);
	}

	deactivateParameterById(id:number) : Observable<number> {

		return this.api.post<number>("Parameter/deactivateParameterById", id);
	}

	deactivateParameter(parameterDTO:ParameterDTO) : Observable<ParameterDTO> {

		return this.api.post<ParameterDTO>("Parameter/deactivateParameter", parameterDTO);
	}

	deactivateParameterList(parameterList:Array<ParameterDTO>) : Observable<Array<ParameterDTO>> {

		return this.api.post<Array<ParameterDTO>>("Parameter/deactivateParameterList", parameterList);
	}
}