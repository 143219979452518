import { INavData } from '@coreui/angular';

export const supplierNavItems: INavData[] = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'icon-speedometer',
    badge: {
      variant: 'info',
      text: 'NEW'
    }
  },
  {
    name: 'My Profile',
    url: '/supplier-admin/supplier-profile',
    icon: 'fa fa-user-circle',
  },
  {
    name: 'Update My Details',
    url: '/supplier-settings/restaurant-update-request',
    icon: 'fa fa-cutlery',

  }, 
  {
  name: 'My Offers',
  url: '/supplier-admin',
  icon: 'fa fa-percent',
  children: [
    {
      name: 'Generated Offers',
      url: '/supplier-admin/customer-offers',
      icon: 'fa fa-tags'
    },
    {
      name: 'Offers',
      url: '/supplier-admin/offers',
      icon: 'fa fa-ticket'
    }
  ]
  },
  {
    name: 'Create Cashier',
    url: '/supplier-admin/supplier-users',
    icon: 'fa fa-users'
  },
  {
    name: 'Statistics',
    url: '/supplier-admin/statistics',
    icon: 'fa fa-bar-chart'
  }
];

export const supplierEmployeeNavItems: INavData[] = [
{
  name: 'Coupons',
  url: '/supplier-employee',
  icon: 'fa fa-ticket',
  children: [
    {
      name: 'Coupon consumption',
      url: '/supplier-employee/coupon-consumption',
      icon: 'fa fa-percent'
    }
  ]
},
];

export const accountManagerNavItems: INavData[] = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'icon-speedometer',
    badge: {
      variant: 'info',
      text: 'NEW'
    }
  },
  {
    name: 'Restaurants',
    url: '/supplier-settings/restaurants',
    icon: 'fa fa-cutlery'
  },
  {
    name: 'Update Requests',
    url: '/supplier-settings/restaurant-update-request',
    icon: 'fa fa-cutlery',

  },
  {
    name: 'User Accounts',
    url: '/users',
    icon: 'fa fa-id-card-o',
    children: [
      {
        name: 'All Users',
        url: '/users/users',
        icon: 'fa fa-users'
      }
    ]
  },
  {
    name: 'Offers',
    url: '/offers',
    icon: 'fa fa-percent',
    children: [
      {
        name: 'Supplier Offers',
        url: '/offers/supplierOffers',
        icon: 'fa fa-ticket'
      },
      {
        name: 'Generated Offers',
        url: '/offers/customerOffers',
        icon: 'fa fa-tags'
      }
    ]
  },
  ];

  export const salesAccountNavItems: INavData[] = [
    {
      name: 'Restaurants',
      url: '/supplier-settings/restaurants',
      icon: 'fa fa-cutlery'
    },{
      name: 'User Accounts',
      url: '/users/users',
      icon: 'fa fa-id-card-o',
    }
    ];
export const adminNavItems: INavData[] = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'icon-speedometer',
    badge: {
      variant: 'info',
      text: 'NEW'
    }
  },
  {
    name: 'Restaurants',
    url: '/supplier-settings/restaurants',
    icon: 'fa fa-cutlery'
  },
  {
    name: 'Update Requests',
    url: '/supplier-settings/restaurant-update-request',
    icon: 'fa fa-cutlery',

  },
//FADOS

{
  name: 'User Accounts',
  url: '/users',
  icon: 'fa fa-id-card-o',
  children: [
    {
      name: 'All Users',
      url: '/users/users',
      icon: 'fa fa-users'
    },
    {
      name: 'App Users',
      url: '/users/customers',
      icon: 'fa fa-id-badge'
    }
  ]
}


// {
//   name: 'Suppliers/Restaurant',
//   url: '/supplier-settings',
//   icon: 'icon-puzzle',
//   children: [
//     // {
//     //   name: 'Suppliers',
//     //   url: '/supplier-settings/suppliers',
//     //   icon: 'icon-puzzle'
//     // },
//     {
//       name: 'Restaurants',
//       url: '/supplier-settings/restaurants',
//       icon: 'icon-puzzle'
//     },
//     // {
//     //   name: 'Supplier Types',
//     //   url: '/supplier-settings/supplierTypes',
//     //   icon: 'icon-puzzle'
//     // }
//   ]
// }
,
{
  name: 'Offers',
  url: '/offers',
  icon: 'fa fa-percent',
  children: [
    {
      name: 'Supplier Offers',
      url: '/offers/supplierOffers',
      icon: 'fa fa-ticket'
    },
    {
      name: 'Generated Offers',
      url: '/offers/customerOffers',
      icon: 'fa fa-tags'
    }
  ]
},
{
  name: 'Settings',
  url: '/settings',
  icon: 'fa fa-cog',
  children: [
    {
      title: true,
      name: 'Restaurant Settings',
      class: 'nav-tabs-hint',
    },
    {
      divider: true
    },
    {
      name: 'Cuisine Types',
      url: '/restaurant/cuisineTypes',
      icon: 'fa fa-code-fork'
    },
    {
      name: 'Designs',
      url: '/restaurant/restDesign',
      icon: 'fa fa-th-large'
    },
    {
      name: 'Destinations',
      url: '/restaurant/restDestination',
      icon: 'fa fa-map-o'
    },
    {
      name: 'Environments',
      url: '/restaurant/restEnvironment',
      icon: 'fa fa-tree'
    },
    {
      name: 'Specialities',
      url: '/restaurant/restSpeciality',
      icon: 'fa fa-industry'
    },
    {
      title: true,
      name: 'General Settings',
      class: 'nav-tabs-hint',
    },
    {
      divider: true
    },
    {
      name: 'Statuses',
      url: '/settings/statuses',
      icon: 'fa fa-tasks'
    },
    {
      name: 'Countries',
      url: '/settings/country',
      icon: 'fa fa-globe'
    },
    {
      name: 'Cities',
      url: '/settings/city',
      icon: 'fa fa-building-o'
    }
  ]
}
//FADOS END
];
