import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Api} from './api.service';
import {SupplierDTO} from '../dto/supplier-dto';
import {TcodeStatusDTO} from '../dto/tcode-status-dto';
import {TcodeSupplierTypeDTO} from '../dto/tcode-supplier-type-dto';
import {TcodeCityDTO} from '../dto/tcode-city-dto';

@Injectable()
export class BaseSupplierService {

	constructor(public api:Api) {

	}

	getSupplierById(id:number) : Observable<SupplierDTO> {

		return this.api.post<SupplierDTO>("Supplier/getSupplierById", id);
	}

	getSupplier(supplierDTO:SupplierDTO) : Observable<SupplierDTO> {

		return this.api.post<SupplierDTO>("Supplier/getSupplier", supplierDTO);
	}

	getSuppliersByStatusId(tcodeStatusDTO:TcodeStatusDTO) : Observable<TcodeStatusDTO> {

		return this.api.post<TcodeStatusDTO>("Supplier/getSuppliersByStatusId", tcodeStatusDTO);
	}

	getSupplierListByStatusId(statusId:number) : Observable<Array<SupplierDTO>> {

		return this.api.post<Array<SupplierDTO>>("Supplier/getSupplierListByStatusId", statusId);
	}

	getSupplierMapByStatusId(statusId:number) : Observable<Map<number, SupplierDTO>>  {

		return this.api.post<Map<number, SupplierDTO>>("Supplier/getSupplierMapByStatusId", statusId);
	}

	getSuppliersBySupplierTypeId(tcodeSupplierTypeDTO:TcodeSupplierTypeDTO) : Observable<TcodeSupplierTypeDTO> {

		return this.api.post<TcodeSupplierTypeDTO>("Supplier/getSuppliersBySupplierTypeId", tcodeSupplierTypeDTO);
	}

	getSupplierListBySupplierTypeId(supplierTypeId:number) : Observable<Array<SupplierDTO>> {

		return this.api.post<Array<SupplierDTO>>("Supplier/getSupplierListBySupplierTypeId", supplierTypeId);
	}

	getSupplierMapBySupplierTypeId(supplierTypeId:number) : Observable<Map<number, SupplierDTO>>  {

		return this.api.post<Map<number, SupplierDTO>>("Supplier/getSupplierMapBySupplierTypeId", supplierTypeId);
	}

	getSuppliersByCityId(tcodeCityDTO:TcodeCityDTO) : Observable<TcodeCityDTO> {

		return this.api.post<TcodeCityDTO>("Supplier/getSuppliersByCityId", tcodeCityDTO);
	}

	getSupplierListByCityId(cityId:number) : Observable<Array<SupplierDTO>> {

		return this.api.post<Array<SupplierDTO>>("Supplier/getSupplierListByCityId", cityId);
	}

	getSupplierMapByCityId(cityId:number) : Observable<Map<number, SupplierDTO>>  {

		return this.api.post<Map<number, SupplierDTO>>("Supplier/getSupplierMapByCityId", cityId);
	}

	getSupplierList() : Observable<SupplierDTO[]> {

		return this.api.get<SupplierDTO[]>("Supplier/getSupplierList");
	}

	getSupplierMap() : Observable<Map<number, SupplierDTO>> {

		return this.api.get<Map<number, SupplierDTO>>("Supplier/getSupplierMap");
	}

	searchSupplierList(supplierDTO:SupplierDTO) : Observable<Array<SupplierDTO>> {

		return this.api.post<Array<SupplierDTO>>("Supplier/searchSupplierList", supplierDTO);
	}

	searchSupplierMap(supplierDTO:SupplierDTO) : Observable<Map<number, SupplierDTO>> {

		return this.api.post<Map<number, SupplierDTO>>("Supplier/searchSupplierMap", supplierDTO);
	}

	addSupplier(supplierDTO:SupplierDTO) : Observable<SupplierDTO> {

		return this.api.post<SupplierDTO>("Supplier/addSupplier", supplierDTO);
	}

	addSupplierList(supplierList:Array<SupplierDTO>) : Observable<Array<SupplierDTO>> {

		return this.api.post<Array<SupplierDTO>>("Supplier/addSupplierList", supplierList);
	}

	updateSupplier(supplierDTO:SupplierDTO) : Observable<SupplierDTO> {

		return this.api.post<SupplierDTO>("Supplier/updateSupplier", supplierDTO);
	}

	updateSupplierList(supplierList:Array<SupplierDTO>) : Observable<Array<SupplierDTO>> {

		return this.api.post<Array<SupplierDTO>>("Supplier/updateSupplierList", supplierList);
	}

	saveSupplier(supplierDTO:SupplierDTO) : Observable<SupplierDTO> {

		return this.api.post<SupplierDTO>("Supplier/saveSupplier", supplierDTO);
	}

	saveSupplierList(supplierList:Array<SupplierDTO>) : Observable<Array<SupplierDTO>> {

		return this.api.post<Array<SupplierDTO>>("Supplier/saveSupplierList", supplierList);
	}

	activateSupplierById(id:number) : Observable<number>{

		return this.api.post<number>("Supplier/activateSupplierById", id);
	}

	activateSupplier(supplierDTO:SupplierDTO) : Observable<SupplierDTO> {

		return this.api.post<SupplierDTO>("Supplier/activateSupplier", supplierDTO);
	}

	activateSupplierList(supplierList:Array<SupplierDTO>) : Observable<Array<SupplierDTO>> {

		return this.api.post<Array<SupplierDTO>>("Supplier/activateSupplierList", supplierList);
	}

	deactivateSupplierById(id:number) : Observable<number> {

		return this.api.post<number>("Supplier/deactivateSupplierById", id);
	}

	deactivateSupplier(supplierDTO:SupplierDTO) : Observable<SupplierDTO> {

		return this.api.post<SupplierDTO>("Supplier/deactivateSupplier", supplierDTO);
	}

	deactivateSupplierList(supplierList:Array<SupplierDTO>) : Observable<Array<SupplierDTO>> {

		return this.api.post<Array<SupplierDTO>>("Supplier/deactivateSupplierList", supplierList);
	}
}