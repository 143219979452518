import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Api} from './api.service';
import {RestaurantRateDTO} from '../dto/restaurant-rate-dto';
import {SupplierDTO} from '../dto/supplier-dto';

@Injectable()
export class BaseRestaurantRateService {

	constructor(public api:Api) {

	}

	getRestaurantRateById(id:number) : Observable<RestaurantRateDTO> {

		return this.api.post<RestaurantRateDTO>("RestaurantRate/getRestaurantRateById", id);
	}

	getRestaurantRate(restaurantRateDTO:RestaurantRateDTO) : Observable<RestaurantRateDTO> {

		return this.api.post<RestaurantRateDTO>("RestaurantRate/getRestaurantRate", restaurantRateDTO);
	}

	getRestaurantRatesBySupplierId(supplierDTO:SupplierDTO) : Observable<SupplierDTO> {

		return this.api.post<SupplierDTO>("RestaurantRate/getRestaurantRatesBySupplierId", supplierDTO);
	}

	getRestaurantRateListBySupplierId(supplierId:number) : Observable<Array<RestaurantRateDTO>> {

		return this.api.post<Array<RestaurantRateDTO>>("RestaurantRate/getRestaurantRateListBySupplierId", supplierId);
	}

	getRestaurantRateMapBySupplierId(supplierId:number) : Observable<Map<number, RestaurantRateDTO>>  {

		return this.api.post<Map<number, RestaurantRateDTO>>("RestaurantRate/getRestaurantRateMapBySupplierId", supplierId);
	}

	getRestaurantRateList() : Observable<RestaurantRateDTO[]> {

		return this.api.get<RestaurantRateDTO[]>("RestaurantRate/getRestaurantRateList");
	}

	getRestaurantRateMap() : Observable<Map<number, RestaurantRateDTO>> {

		return this.api.get<Map<number, RestaurantRateDTO>>("RestaurantRate/getRestaurantRateMap");
	}

	searchRestaurantRateList(restaurantRateDTO:RestaurantRateDTO) : Observable<Array<RestaurantRateDTO>> {

		return this.api.post<Array<RestaurantRateDTO>>("RestaurantRate/searchRestaurantRateList", restaurantRateDTO);
	}

	searchRestaurantRateMap(restaurantRateDTO:RestaurantRateDTO) : Observable<Map<number, RestaurantRateDTO>> {

		return this.api.post<Map<number, RestaurantRateDTO>>("RestaurantRate/searchRestaurantRateMap", restaurantRateDTO);
	}

	addRestaurantRate(restaurantRateDTO:RestaurantRateDTO) : Observable<RestaurantRateDTO> {

		return this.api.post<RestaurantRateDTO>("RestaurantRate/addRestaurantRate", restaurantRateDTO);
	}

	addRestaurantRateList(restaurantRateList:Array<RestaurantRateDTO>) : Observable<Array<RestaurantRateDTO>> {

		return this.api.post<Array<RestaurantRateDTO>>("RestaurantRate/addRestaurantRateList", restaurantRateList);
	}

	updateRestaurantRate(restaurantRateDTO:RestaurantRateDTO) : Observable<RestaurantRateDTO> {

		return this.api.post<RestaurantRateDTO>("RestaurantRate/updateRestaurantRate", restaurantRateDTO);
	}

	updateRestaurantRateList(restaurantRateList:Array<RestaurantRateDTO>) : Observable<Array<RestaurantRateDTO>> {

		return this.api.post<Array<RestaurantRateDTO>>("RestaurantRate/updateRestaurantRateList", restaurantRateList);
	}

	saveRestaurantRate(restaurantRateDTO:RestaurantRateDTO) : Observable<RestaurantRateDTO> {

		return this.api.post<RestaurantRateDTO>("RestaurantRate/saveRestaurantRate", restaurantRateDTO);
	}

	saveRestaurantRateList(restaurantRateList:Array<RestaurantRateDTO>) : Observable<Array<RestaurantRateDTO>> {

		return this.api.post<Array<RestaurantRateDTO>>("RestaurantRate/saveRestaurantRateList", restaurantRateList);
	}

	activateRestaurantRateById(id:number) : Observable<number>{

		return this.api.post<number>("RestaurantRate/activateRestaurantRateById", id);
	}

	activateRestaurantRate(restaurantRateDTO:RestaurantRateDTO) : Observable<RestaurantRateDTO> {

		return this.api.post<RestaurantRateDTO>("RestaurantRate/activateRestaurantRate", restaurantRateDTO);
	}

	activateRestaurantRateList(restaurantRateList:Array<RestaurantRateDTO>) : Observable<Array<RestaurantRateDTO>> {

		return this.api.post<Array<RestaurantRateDTO>>("RestaurantRate/activateRestaurantRateList", restaurantRateList);
	}

	deactivateRestaurantRateById(id:number) : Observable<number> {

		return this.api.post<number>("RestaurantRate/deactivateRestaurantRateById", id);
	}

	deactivateRestaurantRate(restaurantRateDTO:RestaurantRateDTO) : Observable<RestaurantRateDTO> {

		return this.api.post<RestaurantRateDTO>("RestaurantRate/deactivateRestaurantRate", restaurantRateDTO);
	}

	deactivateRestaurantRateList(restaurantRateList:Array<RestaurantRateDTO>) : Observable<Array<RestaurantRateDTO>> {

		return this.api.post<Array<RestaurantRateDTO>>("RestaurantRate/deactivateRestaurantRateList", restaurantRateList);
	}
}