import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Api} from './api.service';
import {TcodeRestEnvironmentDTO} from '../dto/tcode-rest-environment-dto';

@Injectable()
export class BaseTcodeRestEnvironmentService {

	constructor(public api:Api) {

	}

	getTcodeRestEnvironmentById(id:number) : Observable<TcodeRestEnvironmentDTO> {

		return this.api.post<TcodeRestEnvironmentDTO>("TcodeRestEnvironment/getTcodeRestEnvironmentById", id);
	}

	getTcodeRestEnvironment(tcodeRestEnvironmentDTO:TcodeRestEnvironmentDTO) : Observable<TcodeRestEnvironmentDTO> {

		return this.api.post<TcodeRestEnvironmentDTO>("TcodeRestEnvironment/getTcodeRestEnvironment", tcodeRestEnvironmentDTO);
	}

	getTcodeRestEnvironmentList() : Observable<TcodeRestEnvironmentDTO[]> {

		return this.api.get<TcodeRestEnvironmentDTO[]>("TcodeRestEnvironment/getTcodeRestEnvironmentList");
	}

	getTcodeRestEnvironmentMap() : Observable<Map<number, TcodeRestEnvironmentDTO>> {

		return this.api.get<Map<number, TcodeRestEnvironmentDTO>>("TcodeRestEnvironment/getTcodeRestEnvironmentMap");
	}

	searchTcodeRestEnvironmentList(tcodeRestEnvironmentDTO:TcodeRestEnvironmentDTO) : Observable<Array<TcodeRestEnvironmentDTO>> {

		return this.api.post<Array<TcodeRestEnvironmentDTO>>("TcodeRestEnvironment/searchTcodeRestEnvironmentList", tcodeRestEnvironmentDTO);
	}

	searchTcodeRestEnvironmentMap(tcodeRestEnvironmentDTO:TcodeRestEnvironmentDTO) : Observable<Map<number, TcodeRestEnvironmentDTO>> {

		return this.api.post<Map<number, TcodeRestEnvironmentDTO>>("TcodeRestEnvironment/searchTcodeRestEnvironmentMap", tcodeRestEnvironmentDTO);
	}

	addTcodeRestEnvironment(tcodeRestEnvironmentDTO:TcodeRestEnvironmentDTO) : Observable<TcodeRestEnvironmentDTO> {

		return this.api.post<TcodeRestEnvironmentDTO>("TcodeRestEnvironment/addTcodeRestEnvironment", tcodeRestEnvironmentDTO);
	}

	addTcodeRestEnvironmentList(tcodeRestEnvironmentList:Array<TcodeRestEnvironmentDTO>) : Observable<Array<TcodeRestEnvironmentDTO>> {

		return this.api.post<Array<TcodeRestEnvironmentDTO>>("TcodeRestEnvironment/addTcodeRestEnvironmentList", tcodeRestEnvironmentList);
	}

	updateTcodeRestEnvironment(tcodeRestEnvironmentDTO:TcodeRestEnvironmentDTO) : Observable<TcodeRestEnvironmentDTO> {

		return this.api.post<TcodeRestEnvironmentDTO>("TcodeRestEnvironment/updateTcodeRestEnvironment", tcodeRestEnvironmentDTO);
	}

	updateTcodeRestEnvironmentList(tcodeRestEnvironmentList:Array<TcodeRestEnvironmentDTO>) : Observable<Array<TcodeRestEnvironmentDTO>> {

		return this.api.post<Array<TcodeRestEnvironmentDTO>>("TcodeRestEnvironment/updateTcodeRestEnvironmentList", tcodeRestEnvironmentList);
	}

	saveTcodeRestEnvironment(tcodeRestEnvironmentDTO:TcodeRestEnvironmentDTO) : Observable<TcodeRestEnvironmentDTO> {

		return this.api.post<TcodeRestEnvironmentDTO>("TcodeRestEnvironment/saveTcodeRestEnvironment", tcodeRestEnvironmentDTO);
	}

	saveTcodeRestEnvironmentList(tcodeRestEnvironmentList:Array<TcodeRestEnvironmentDTO>) : Observable<Array<TcodeRestEnvironmentDTO>> {

		return this.api.post<Array<TcodeRestEnvironmentDTO>>("TcodeRestEnvironment/saveTcodeRestEnvironmentList", tcodeRestEnvironmentList);
	}

	activateTcodeRestEnvironmentById(id:number) : Observable<number>{

		return this.api.post<number>("TcodeRestEnvironment/activateTcodeRestEnvironmentById", id);
	}

	activateTcodeRestEnvironment(tcodeRestEnvironmentDTO:TcodeRestEnvironmentDTO) : Observable<TcodeRestEnvironmentDTO> {

		return this.api.post<TcodeRestEnvironmentDTO>("TcodeRestEnvironment/activateTcodeRestEnvironment", tcodeRestEnvironmentDTO);
	}

	activateTcodeRestEnvironmentList(tcodeRestEnvironmentList:Array<TcodeRestEnvironmentDTO>) : Observable<Array<TcodeRestEnvironmentDTO>> {

		return this.api.post<Array<TcodeRestEnvironmentDTO>>("TcodeRestEnvironment/activateTcodeRestEnvironmentList", tcodeRestEnvironmentList);
	}

	deactivateTcodeRestEnvironmentById(id:number) : Observable<number> {

		return this.api.post<number>("TcodeRestEnvironment/deactivateTcodeRestEnvironmentById", id);
	}

	deactivateTcodeRestEnvironment(tcodeRestEnvironmentDTO:TcodeRestEnvironmentDTO) : Observable<TcodeRestEnvironmentDTO> {

		return this.api.post<TcodeRestEnvironmentDTO>("TcodeRestEnvironment/deactivateTcodeRestEnvironment", tcodeRestEnvironmentDTO);
	}

	deactivateTcodeRestEnvironmentList(tcodeRestEnvironmentList:Array<TcodeRestEnvironmentDTO>) : Observable<Array<TcodeRestEnvironmentDTO>> {

		return this.api.post<Array<TcodeRestEnvironmentDTO>>("TcodeRestEnvironment/deactivateTcodeRestEnvironmentList", tcodeRestEnvironmentList);
	}
}