import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Api} from './api.service';
import {CustomerOfferDTO} from '../dto/customer-offer-dto';
import {CustomerDTO} from '../dto/customer-dto';
import {OfferDTO} from '../dto/offer-dto';
import {TcodeRejectionReasonDTO} from '../dto/tcode-rejection-reason-dto';

@Injectable()
export class BaseCustomerOfferService {

	constructor(public api:Api) {

	}

	getCustomerOfferById(id:number) : Observable<CustomerOfferDTO> {

		return this.api.post<CustomerOfferDTO>("CustomerOffer/getCustomerOfferById", id);
	}

	getBasicCustomerOfferById(id:number) : Observable<CustomerOfferDTO> {

		return this.api.get<CustomerOfferDTO>("CustomerOffer/getBasicCustomerOfferById/"+ id);
	}

	getCustomerOffer(customerOfferDTO:CustomerOfferDTO) : Observable<CustomerOfferDTO> {

		return this.api.post<CustomerOfferDTO>("CustomerOffer/getCustomerOffer", customerOfferDTO);
	}

	getCustomerOffersByCustomerId(customerDTO:CustomerDTO) : Observable<CustomerDTO> {

		return this.api.post<CustomerDTO>("CustomerOffer/getCustomerOffersByCustomerId", customerDTO);
	}

	getCustomerOfferListByCustomerId(customerId:number) : Observable<Array<CustomerOfferDTO>> {

		return this.api.post<Array<CustomerOfferDTO>>("CustomerOffer/getCustomerOfferListByCustomerId", customerId);
	}

	getCustomerOfferMapByCustomerId(customerId:number) : Observable<Map<number, CustomerOfferDTO>>  {

		return this.api.post<Map<number, CustomerOfferDTO>>("CustomerOffer/getCustomerOfferMapByCustomerId", customerId);
	}

	getCustomerOffersByOfferId(offerDTO:OfferDTO) : Observable<OfferDTO> {

		return this.api.post<OfferDTO>("CustomerOffer/getCustomerOffersByOfferId", offerDTO);
	}

	getCustomerOfferListByOfferId(offerId:number) : Observable<Array<CustomerOfferDTO>> {

		return this.api.post<Array<CustomerOfferDTO>>("CustomerOffer/getCustomerOfferListByOfferId", offerId);
	}

	getCustomerOfferMapByOfferId(offerId:number) : Observable<Map<number, CustomerOfferDTO>>  {

		return this.api.post<Map<number, CustomerOfferDTO>>("CustomerOffer/getCustomerOfferMapByOfferId", offerId);
	}

	getCustomerOffersByRejectionReason(tcodeRejectionReasonDTO:TcodeRejectionReasonDTO) : Observable<TcodeRejectionReasonDTO> {

		return this.api.post<TcodeRejectionReasonDTO>("CustomerOffer/getCustomerOffersByRejectionReason", tcodeRejectionReasonDTO);
	}

	getCustomerOfferListByRejectionReason(rejectionReason:number) : Observable<Array<CustomerOfferDTO>> {

		return this.api.post<Array<CustomerOfferDTO>>("CustomerOffer/getCustomerOfferListByRejectionReason", rejectionReason);
	}

	getCustomerOfferMapByRejectionReason(rejectionReason:number) : Observable<Map<number, CustomerOfferDTO>>  {

		return this.api.post<Map<number, CustomerOfferDTO>>("CustomerOffer/getCustomerOfferMapByRejectionReason", rejectionReason);
	}

	getCustomerOfferList() : Observable<CustomerOfferDTO[]> {

		return this.api.get<CustomerOfferDTO[]>("CustomerOffer/getCustomerOfferList");
	}

	getCustomerOfferMap() : Observable<Map<number, CustomerOfferDTO>> {

		return this.api.get<Map<number, CustomerOfferDTO>>("CustomerOffer/getCustomerOfferMap");
	}

	searchCustomerOfferList(customerOfferDTO:CustomerOfferDTO) : Observable<Array<CustomerOfferDTO>> {

		return this.api.post<Array<CustomerOfferDTO>>("CustomerOffer/searchCustomerOfferList", customerOfferDTO);
	}

	searchCustomerOfferMap(customerOfferDTO:CustomerOfferDTO) : Observable<Map<number, CustomerOfferDTO>> {

		return this.api.post<Map<number, CustomerOfferDTO>>("CustomerOffer/searchCustomerOfferMap", customerOfferDTO);
	}

	addCustomerOffer(customerOfferDTO:CustomerOfferDTO) : Observable<CustomerOfferDTO> {

		return this.api.post<CustomerOfferDTO>("CustomerOffer/addCustomerOffer", customerOfferDTO);
	}

	addCustomerOfferList(customerOfferList:Array<CustomerOfferDTO>) : Observable<Array<CustomerOfferDTO>> {

		return this.api.post<Array<CustomerOfferDTO>>("CustomerOffer/addCustomerOfferList", customerOfferList);
	}

	updateCustomerOffer(customerOfferDTO:CustomerOfferDTO) : Observable<CustomerOfferDTO> {

		return this.api.post<CustomerOfferDTO>("CustomerOffer/updateCustomerOffer", customerOfferDTO);
	}

	updateCustomerOfferList(customerOfferList:Array<CustomerOfferDTO>) : Observable<Array<CustomerOfferDTO>> {

		return this.api.post<Array<CustomerOfferDTO>>("CustomerOffer/updateCustomerOfferList", customerOfferList);
	}

	saveCustomerOffer(customerOfferDTO:CustomerOfferDTO) : Observable<CustomerOfferDTO> {

		return this.api.post<CustomerOfferDTO>("CustomerOffer/saveCustomerOffer", customerOfferDTO);
	}

	saveCustomerOfferList(customerOfferList:Array<CustomerOfferDTO>) : Observable<Array<CustomerOfferDTO>> {

		return this.api.post<Array<CustomerOfferDTO>>("CustomerOffer/saveCustomerOfferList", customerOfferList);
	}

	activateCustomerOfferById(id:number) : Observable<number>{

		return this.api.post<number>("CustomerOffer/activateCustomerOfferById", id);
	}

	activateCustomerOffer(customerOfferDTO:CustomerOfferDTO) : Observable<CustomerOfferDTO> {

		return this.api.post<CustomerOfferDTO>("CustomerOffer/activateCustomerOffer", customerOfferDTO);
	}

	activateCustomerOfferList(customerOfferList:Array<CustomerOfferDTO>) : Observable<Array<CustomerOfferDTO>> {

		return this.api.post<Array<CustomerOfferDTO>>("CustomerOffer/activateCustomerOfferList", customerOfferList);
	}

	deactivateCustomerOfferById(id:number) : Observable<number> {

		return this.api.post<number>("CustomerOffer/deactivateCustomerOfferById", id);
	}

	deactivateCustomerOffer(customerOfferDTO:CustomerOfferDTO) : Observable<CustomerOfferDTO> {

		return this.api.post<CustomerOfferDTO>("CustomerOffer/deactivateCustomerOffer", customerOfferDTO);
	}

	deactivateCustomerOfferList(customerOfferList:Array<CustomerOfferDTO>) : Observable<Array<CustomerOfferDTO>> {

		return this.api.post<Array<CustomerOfferDTO>>("CustomerOffer/deactivateCustomerOfferList", customerOfferList);
	}
}