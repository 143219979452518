import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Api} from './api.service';
import {RestaurantUpdateRequestDTO} from '../dto/restaurant-update-request-dto';
import {RestaurantDTO} from '../dto/restaurant-dto';
import {TcodeStatusDTO} from '../dto/tcode-status-dto';

@Injectable()
export class BaseRestaurantUpdateRequestService {

	constructor(public api:Api) {

	}

	getRestaurantUpdateRequestById(id:number) : Observable<RestaurantUpdateRequestDTO> {

		return this.api.post<RestaurantUpdateRequestDTO>("RestaurantUpdateRequest/getRestaurantUpdateRequestById", id);
	}

	getRestaurantUpdateRequest(restaurantUpdateRequestDTO:RestaurantUpdateRequestDTO) : Observable<RestaurantUpdateRequestDTO> {

		return this.api.post<RestaurantUpdateRequestDTO>("RestaurantUpdateRequest/getRestaurantUpdateRequest", restaurantUpdateRequestDTO);
	}

	getRestaurantUpdateRequestsByRestaurantId(restaurantDTO:RestaurantDTO) : Observable<RestaurantDTO> {

		return this.api.post<RestaurantDTO>("RestaurantUpdateRequest/getRestaurantUpdateRequestsByRestaurantId", restaurantDTO);
	}

	getRestaurantUpdateRequestListByRestaurantId(restaurantId:number) : Observable<Array<RestaurantUpdateRequestDTO>> {

		return this.api.post<Array<RestaurantUpdateRequestDTO>>("RestaurantUpdateRequest/getRestaurantUpdateRequestListByRestaurantId", restaurantId);
	}

	getRestaurantUpdateRequestMapByRestaurantId(restaurantId:number) : Observable<Map<number, RestaurantUpdateRequestDTO>>  {

		return this.api.post<Map<number, RestaurantUpdateRequestDTO>>("RestaurantUpdateRequest/getRestaurantUpdateRequestMapByRestaurantId", restaurantId);
	}

	getRestaurantUpdateRequestsByStatus(tcodeStatusDTO:TcodeStatusDTO) : Observable<TcodeStatusDTO> {

		return this.api.post<TcodeStatusDTO>("RestaurantUpdateRequest/getRestaurantUpdateRequestsByStatus", tcodeStatusDTO);
	}

	getRestaurantUpdateRequestListByStatus(status:number) : Observable<Array<RestaurantUpdateRequestDTO>> {

		return this.api.post<Array<RestaurantUpdateRequestDTO>>("RestaurantUpdateRequest/getRestaurantUpdateRequestListByStatus", status);
	}

	getRestaurantUpdateRequestMapByStatus(status:number) : Observable<Map<number, RestaurantUpdateRequestDTO>>  {

		return this.api.post<Map<number, RestaurantUpdateRequestDTO>>("RestaurantUpdateRequest/getRestaurantUpdateRequestMapByStatus", status);
	}

	getRestaurantUpdateRequestList() : Observable<RestaurantUpdateRequestDTO[]> {

		return this.api.get<RestaurantUpdateRequestDTO[]>("RestaurantUpdateRequest/getRestaurantUpdateRequestList");
	}

	getRestaurantUpdateRequestMap() : Observable<Map<number, RestaurantUpdateRequestDTO>> {

		return this.api.get<Map<number, RestaurantUpdateRequestDTO>>("RestaurantUpdateRequest/getRestaurantUpdateRequestMap");
	}

	searchRestaurantUpdateRequestList(restaurantUpdateRequestDTO:RestaurantUpdateRequestDTO) : Observable<Array<RestaurantUpdateRequestDTO>> {

		return this.api.post<Array<RestaurantUpdateRequestDTO>>("RestaurantUpdateRequest/searchRestaurantUpdateRequestList", restaurantUpdateRequestDTO);
	}

	exportRestaurantUpdateRequestList(restaurantUpdateRequestDTO:RestaurantUpdateRequestDTO) : void {

		return this.api.download("RestaurantUpdateRequest/exportRestaurantUpdateRequestList", restaurantUpdateRequestDTO, "Restaurant Update Request List");
	}

	searchRestaurantUpdateRequestMap(restaurantUpdateRequestDTO:RestaurantUpdateRequestDTO) : Observable<Map<number, RestaurantUpdateRequestDTO>> {

		return this.api.post<Map<number, RestaurantUpdateRequestDTO>>("RestaurantUpdateRequest/searchRestaurantUpdateRequestMap", restaurantUpdateRequestDTO);
	}

	addRestaurantUpdateRequest(restaurantUpdateRequestDTO:RestaurantUpdateRequestDTO) : Observable<RestaurantUpdateRequestDTO> {

		return this.api.post<RestaurantUpdateRequestDTO>("RestaurantUpdateRequest/addRestaurantUpdateRequest", restaurantUpdateRequestDTO);
	}

	updateRestaurantUpdateRequest(restaurantUpdateRequestDTO:RestaurantUpdateRequestDTO) : Observable<RestaurantUpdateRequestDTO> {

		return this.api.post<RestaurantUpdateRequestDTO>("RestaurantUpdateRequest/updateRestaurantUpdateRequest", restaurantUpdateRequestDTO);
	}

	saveRestaurantUpdateRequest(restaurantUpdateRequestDTO:RestaurantUpdateRequestDTO) : Observable<RestaurantUpdateRequestDTO> {

		return this.api.post<RestaurantUpdateRequestDTO>("RestaurantUpdateRequest/saveRestaurantUpdateRequest", restaurantUpdateRequestDTO);
	}

	activateRestaurantUpdateRequestById(id:number) : Observable<number>{

		return this.api.post<number>("RestaurantUpdateRequest/activateRestaurantUpdateRequestById", id);
	}

	activateRestaurantUpdateRequest(restaurantUpdateRequestDTO:RestaurantUpdateRequestDTO) : Observable<RestaurantUpdateRequestDTO> {

		return this.api.post<RestaurantUpdateRequestDTO>("RestaurantUpdateRequest/activateRestaurantUpdateRequest", restaurantUpdateRequestDTO);
	}

	deactivateRestaurantUpdateRequestById(id:number) : Observable<number> {

		return this.api.post<number>("RestaurantUpdateRequest/deactivateRestaurantUpdateRequestById", id);
	}

	deactivateRestaurantUpdateRequest(restaurantUpdateRequestDTO:RestaurantUpdateRequestDTO) : Observable<RestaurantUpdateRequestDTO> {

		return this.api.post<RestaurantUpdateRequestDTO>("RestaurantUpdateRequest/deactivateRestaurantUpdateRequest", restaurantUpdateRequestDTO);
	}
}